const initState = {
  token: localStorage.getItem("token"),
  email: localStorage.getItem("email"),
  username: localStorage.getItem("username"),
  timestamp: localStorage.getItem("timestamp"),
  languageTempPasswords: localStorage.getItem("languageTempPasswords")
};

export const authenticationReducer = (state = initState, action) => {
  let newState = {};
  switch (action.type) {
    case "ADD_TOKEN_EMAIL":
      newState = {
        ...state,
        token: action.payload,
        email: action.email,
        username: action.username,
        timestamp: action.time
      };
      return newState;
    case "ADD_EMAIL":
      newState = {
        ...state,
        email: action.payload
      };
      return newState;
    case "REMOVE_TOKEN_EMAIL":
      newState = {
        ...state,
        token: "",
        email: "",
        username: "",
        timestamp: ""
      };
      return newState;
    case "Add_Language_Temp_Password_To_Store":
      let newArr = [];
      var obj = {};
      obj["name"] = action.payload;
      obj["temporary_password"] = action.temporary_password;
      newArr.push(obj);

      newState = {
        ...state,
        languageTempPasswords: newArr
      };
      return newState;
    default:
      return state;
  }
};
