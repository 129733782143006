import React, { Component } from "react";
import Header from "../../header/header";
import { connect } from "react-redux";
import { userService } from "../../../services/user.service";
import { Redirect } from "react-router-dom";
import { Grid, Segment } from "semantic-ui-react";
import Footer from "../../../components/footer/footer";
import { Form, Input, TextArea, Button, Select } from "semantic-ui-react";
import { Header as SemanticHeader } from "semantic-ui-react";
import { VerticalSidebar } from "../../sidebar/sidebar";

class Settings extends Component {
  state = {
    redirect: false,
    private: false,
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    age: "",
    gender: "",
    bio: ""
  };

  componentDidMount() {
    userService.show(this.props.token, this.props.username).then(res => {
      console.log("res", res);
      this.setState({
        private: res.data.user.private,
        username: res.data.user.username,
        email: res.data.user.local.email,
        first_name: res.data.user.firstname,
        last_name: res.data.user.lastname,
        age: res.data.user.age,
        gender: res.data.user.gender,
        bio: res.data.user.bio
      });
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    userService
      .update(
        this.props.token,
        this.props.username,
        this.state.first_name,
        this.state.last_name,
        this.state.age,
        this.state.gender,
        this.state.bio
      )
      .then(response => {
        alert("updated");
      });
  };

  handleDelete = () => {
    userService
      .delete_user(this.props.username, this.props.token)
      .then(response => {
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        localStorage.removeItem("timestamp");
        localStorage.removeItem("username");
        this.setState({ redirect: true });
        window.location.reload();
      });
  };

  handleClick = () => {
    userService
      .user_private(this.props.username, this.props.token, true)
      .then(res => {
        this.setState({
          private: true
        });
      });
  };

  handlePublicClick = () => {
    userService
      .user_private(this.props.username, this.props.token, false)
      .then(res => {
        this.setState({
          private: false
        });
      });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleGenderSelectChange = event => {
    this.setState({
      gender: event.target.innerText
    });
  };

  private_show() {
    return this.state.private ? (
      <div>
        <SemanticHeader
          as="h4"
          content="Your profile is private at the moment. To make it public click on the
          link below"
          textAlign="left"
        />
        <Form.Field
          content="Make profile public"
          color="purple"
          control={Button}
          position="right"
          onClick={this.handlePublicClick}
          label=""
        />
      </div>
    ) : (
      <div>
        <SemanticHeader
          as="h4"
          content=" Your profile is public at the moment. To make your profile private
          click on the link below"
          textAlign="left"
        />
        <Form.Field
          content="Make profile private"
          color="purple"
          control={Button}
          position="right"
          onClick={this.handleClick}
          label=""
        />
      </div>
    );
  }

  gender = () => {
    console.log("state", this.state);
    return this.state.gender;
  };

  genderOptions = () => {
    return [
      { key: "m", text: "Male", value: "Male" },
      { key: "f", text: "Female", value: "Female" },
      { key: "o", text: "Other", value: "Other" }
    ];
  };

  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="languages">
        <Header />
        <div className="section_content">
          <div className="">
            <h3>Hi , your generated username is {this.state.username}</h3>
          </div>
        </div>
        <Form className="form" onSubmit={this.handleSubmit}>
          <SemanticHeader
            as="h4"
            content="Please enter the basic account details below"
            textAlign="left"
          />

          <Form.Group widths="equal">
            <Form.Field
              id="form-input-control-first-name"
              control={Input}
              label="First name"
              placeholder="First name"
              value={this.state.first_name}
              name="first_name"
              onChange={this.handleChange}
              required
            />
            <Form.Field
              id="form-input-control-last-name"
              control={Input}
              label="Last name"
              placeholder="Last name"
              value={this.state.last_name}
              name="last_name"
              onChange={this.handleChange}
              required
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              id="form-input-control-error-email"
              control={Input}
              label="Email"
              placeholder=""
              value={this.state.email}
              name="email"
              readOnly
            />
            <Form.Field
              id="form-select-control-age"
              control={Input}
              label="Age"
              placeholder="Age"
              value={this.state.age}
              name="age"
              onChange={this.handleChange}
              type="number"
              required
            />
            <Form.Field
              control={Select}
              label="Gender"
              options={this.genderOptions()}
              placeholder="Gender"
              value={this.state.gender}
              onChange={this.handleGenderSelectChange}
              defaultValue={this.gender()}
            />
          </Form.Group>
          <Form.Field
            id="form-textarea-control-opinion"
            control={TextArea}
            label="Bio"
            placeholder="Bio"
            value={this.state.bio}
            name="bio"
            onChange={this.handleChange}
            required
          />

          <Form.Field
            id="form-button-control-public"
            control={Button}
            content="Submit"
            color="violet"
            position="right"
            textAlign="right"
            float="right"
            right
            label=""
          />
        </Form>
        <Grid stackable columns={2} className="form">
          <Grid.Column className="no-left-right-padding">
            {this.private_show()}
          </Grid.Column>
          <Grid.Column className="left-border">
            <div>
              <h4>To delete your profile please click on the link below</h4>
              <br />
              <Form.Field
                content="Delete Profile"
                color="red"
                control={Button}
                position="right"
                onClick={this.handleDelete}
                textAlign="right"
                label=""
              />
            </div>
          </Grid.Column>
        </Grid>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(Settings);
