import axios from "axios";

function create(
  pname,
  description,
  source_code_link,
  links,
  version_name,
  language_tags,
  categories,
  token
) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };
  const body = {
    name: pname,
    description: description,
    source_code_link: source_code_link,
    links: links,
    version_name: version_name,
    language_tags: categories
  };

  return axios
    .post(process.env.REACT_APP_BACKEND_URL + "/api/v1/languages", body, auth)
    .then(result => {});
}

function index(username, token) {
  const token_val = token ? "Token " + token : null;

  const auth = {
    headers: { Authorization: token_val }
  };

  return axios.get(
    process.env.REACT_APP_BACKEND_URL +
      "/api/v1/users/" +
      username +
      "/languages",
    auth
  );
}

function find(id, token, username) {
  const token_val = token ? "Token " + token : null;

  const auth = {
    headers: { Authorization: token_val }
  };
  return axios.get(
    process.env.REACT_APP_BACKEND_URL +
      "/api/v1/users/" +
      username +
      "/language/" +
      id,
    auth
  );
}

function show(id, token, username) {
  const token_val = token ? "Token " + token : null;

  const auth = {
    headers: { Authorization: token_val }
  };
  return axios.get(
    process.env.REACT_APP_BACKEND_URL +
      "/api/v1/users/" +
      username +
      "/language/" +
      id,
    auth
  );
}

function update(
  pname,
  description,
  source_code_link,
  links,
  version_name,
  language_tags,
  categories,
  token,
  id
) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };
  const body = {
    name: pname,
    description: description,
    source_code_link: source_code_link,
    links: links,
    version_name: version_name,
    language_tags: categories
  };
  return axios.put(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/language/" + id,
    body,
    auth
  );
}

function destroy(id, token) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };

  return axios.delete(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/language/" + id,
    auth
  );
}

function checkPrivate(language_id, username, temporary_password, token) {
  //const token_val = token ? "Token " + token : null;
  //const temp = temporary_password ? temporary_password : "";

  const auth = {
    headers: {
      Authorization: "Token " + token,
      temporaryPassword: temporary_password
    }
  };

  return axios.get(
    process.env.REACT_APP_BACKEND_URL +
      "/api/v1/users/" +
      username +
      "/language/checkprivate/" +
      language_id,
    auth
  );
}

function language_private(id, token, username, private_param) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };

  const body = {
    id: id,
    private: private_param
  };

  return axios.post(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/languages/private/" + id,
    body,
    auth
  );
}

export const languageService = {
  create,
  index,
  show,
  find,
  update,
  destroy,
  checkPrivate,
  language_private
};
