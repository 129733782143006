import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";

class ParsedHTML extends Component {
  state = {};
  render() {
    return <div className="content">{ReactHtmlParser(this.props.content)}</div>;
  }
}

export default ParsedHTML;
