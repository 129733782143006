import React, { Component } from "react";
import Header from "../../header/header";
import Sidebar from "../../sidebar/sidebar";
import { Button, Form } from "semantic-ui-react";
import { languageService } from "../../../services/language.service";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import { categoryService } from "../../../services/category.service";
import { Grid, Segment } from "semantic-ui-react";
import Footer from "../../../components/footer/footer";
import { Link } from "react-router-dom";
import { Tab, Input, TextArea, Icon } from "semantic-ui-react";
import { Header as SemanticHeader } from "semantic-ui-react";

class newLanguage extends Component {
  state = {
    name: "",
    language_tags: "",
    description: "",
    source_code_link: "",
    links: [],
    version: "",
    redirect: false,
    categories: [],
    category_ids: [],
    version_name: ""
  };

  componentDidMount() {
    categoryService
      .index(this.props.username, this.props.token)
      .then(response => {
        let newCategories = response.data;
        this.setState({ categories: newCategories });
      });
  }

  handleSubmit = e => {
    e.preventDefault();
    languageService
      .create(
        this.state.name,
        this.state.description,
        this.state.source_code_link,
        this.state.links,
        this.state.version_name,
        this.state.language_tags,
        this.state.category_ids,
        this.props.token
      )
      .then(response => {
        return this.setState({ redirect: true });
      });
  };

  goBack = e => {
    this.props.history.goBack();
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleArrayChange = (element, event) => {
    let newArray = [...this.state[element]];
    let id = event.target.id;
    newArray[id.replace("element_", "")] = event.target.value;
    newArray = newArray.filter(function(element) {
      return element !== undefined;
    });
    let newHash = {};
    newHash[element] = newArray;
    this.setState(newHash);
  };

  handleAddElement = element => {
    let newHash = {};
    newHash[element] = [...this.state[element], ""];
    this.setState(newHash);
  };

  handleRemoveElement = (element, id) => {
    let newArray = this.state[element].map((el, index) => {
      if (id === "element_" + index) {
        return undefined;
      }
      return el;
    });
    newArray = newArray.filter(function(el) {
      return el !== undefined;
    });
    let newHash = {};
    newHash[element] = newArray;
    this.setState(newHash);
  };

  handleSelect = (field, opts) => {
    let newCategories = [];
    newCategories = opts.map(opt => opt["value"]);
    this.setState({ category_ids: newCategories });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/admin/cheatsheets/" />;
    }

    return (
      <div>
        <Header />
        <div className="section_content">
          <div className="">
            <a onClick={this.goBack} href="#" class="back-link">
              <Icon name="angle left" /> Go Back
            </a>
          </div>
        </div>
        <Form className="form" onSubmit={this.handleSubmit}>
          <SemanticHeader as="h4" content="New cheatsheet" textAlign="left" />

          <Form.Group widths="equal">
            <Form.Field
              fluid
              placeholder="name"
              value={this.state.name}
              label="Name"
              name="name"
              onChange={this.handleChange}
              required
              id="form-input-control-error-name"
              control={Input}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field
              id="form-input-control-error-name"
              control={Select}
              isMulti
              label="Select cheatsheet type"
              options={this.state.categories.map(category => {
                return { label: category.name, value: category._id };
              })}
              value={this.state.categories.map(category =>
                this.state.category_ids.includes(category._id)
                  ? { label: category.name, value: category._id }
                  : ""
              )}
              name="category_id"
              onChange={opts => this.handleSelect("category_ids", opts)}
              required
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field
              fluid
              placeholder="version"
              value={this.state.version_name}
              label="Version"
              name="version_name"
              onChange={this.handleChange}
              required
              id="form-input-control-error-version"
              control={Input}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              fluid
              placeholder="Source code link"
              value={this.state.source_code_link}
              label="Souce code link"
              name="source_code_link"
              onChange={this.handleChange}
              required
              id="form-input-control-error-source_code_link"
              control={Input}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field
              fluid
              placeholder="Description"
              value={this.state.description}
              label="Description"
              name="description"
              onChange={this.handleChange}
              required
              id="form-input-control-error-description"
              control={TextArea}
            />
          </Form.Group>

          <Form.Field
            id="form-button-control-public"
            control={Button}
            content="Submit"
            color="violet"
            position="right"
            textAlign="right"
            float="right"
            right
            label=""
          />
        </Form>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(newLanguage);
