import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Input, Menu } from "semantic-ui-react";
import { Dropdown, Button, Icon } from "semantic-ui-react";
import { userService } from "../../services/user.service";
import { UserDropDown } from "../userDropDown/userDropDown";
import { connect } from "react-redux";
import { VerticalSidebar } from "../sidebar/sidebar";
import { categoryService } from "../../services";
import { mainService } from "../../services/main.service";

import Nav from "../../components/nav/nav";

import "./header.css";

class Header extends Component {
  state = {
    activeItem: "home",
    logged: false,
    search: "",
    visible: false,
    tags: [],
    compare: []
  };

  componentDidMount() {
    this.loggedIn();
    return this.props.token ? this.getTagsFromDB() : "";
  }

  handleChange = event => {
    this.setState({
      search: event.target.value
    });
    this.props.filter_data(event);
  };

  handleNavChange = event => {
    this.setState(prevState => ({ visible: !prevState.visible }));
  };

  loggedIn = () => {
    if (
      this.props.email == null ||
      this.props.token == null ||
      this.props.timestamp - new Date() < 60 * 60 * 24 * 5
    ) {
      return this.setState({ logged: false });
    }
    return this.setState({ logged: true });
  };

  getTagsFromDB = () => {
    categoryService.index(this.props.email, this.props.token).then(res => {
      this.setState({
        tags: res.data
      });

      categoryService.compare(this.props.email, this.props.token).then(res => {
        this.setState({
          compare: res.data
        });
      });

      mainService.main(this.props.username, res.data[0]._id).then(res => {
        this.setState({
          data: res.data.properties,
          languages_data: res.data.languages,
          properties: res.data.properties,
          languages: res.data.languages.map(language => language.name),
          defaultLanguages: res.data.languages.map(language => language.name)
        });
      });
    });
  };

  handleLogout = () => {
    const token = this.props.token;
    userService.logout(token).then(response => {
      console.log("Logged out");
    });
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    localStorage.removeItem("timestamp");
    localStorage.removeItem("username");
    this.setState({ logged: false });
    window.location.reload();
  };

  loggedinLinks = () => {
    return this.state.logged === true ? (
      <React.Fragment>
        <Menu.Item>
          <UserDropDown className="ui inverted" token={this.props.token} />
        </Menu.Item>
        {/* <Menu.Item>
          <Dropdown text="Admin" className="ui inverted">
            <Dropdown.Menu>
              <Dropdown.Item className="border-bottom">
                <Link to="/admin/languages" className="linker-nav">
                  Languages
                </Link>
              </Dropdown.Item>
              <Dropdown.Item className="border-bottom">
                <Link to="/admin/properties" className="linker-nav">
                  Features
                </Link>
              </Dropdown.Item>
              <Dropdown.Item className="border-bottom">
                <Link to="/admin/categories" className="linker-nav">
                  Tags
                </Link>
              </Dropdown.Item>
              <Dropdown.Item className="border-bottom">
                <Link to="/admin/syntaxes" className="linker-nav">
                  Posts
                </Link>
              </Dropdown.Item>
              <Dropdown.Item className="border-bottom">
                <Link to="/admin/settings" className="linker-nav">
                  Settings
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
        <Menu.Item name="Logout" className="authentication_buttons">
          <Link
            to="#"
            onClick={() => this.handleLogout()}
            className="ui inverted black"
          >
            Logout
          </Link>
        </Menu.Item> */}
      </React.Fragment>
    ) : (
      <Menu.Item name="login" onClick={this.handleItemClick}>
        <Link to="/login">
          <Button className="ui orange button white">Login</Button>
        </Link>
      </Menu.Item>
    );
  };

  searchbar = () => {
    return this.props.search === true ? (
      <Menu.Item>
        <Input
          icon="search"
          placeholder="Search Property..."
          value={this.state.search}
          onChange={e => this.handleChange(e)}
        />
      </Menu.Item>
    ) : (
      ""
    );
  };

  hamburger = () => {
    return (
      <Menu.Item>
        <Button
          icon="sidebar"
          onClick={e => this.handleNavChange(e)}
          className="hamburger"
        />
      </Menu.Item>
    );
  };

  render() {
    const { activeItem } = this.state.activeItem;

    return (
      <div className="header">
        <Menu secondary>
          <Menu.Item name="home" active={activeItem === "home"}>
            <Link to="/">
              <h2 className="">
                <img src="/logo.svg" alt="CSheet" class="logo" />
              </h2>
            </Link>
            <pre className="white"></pre>
          </Menu.Item>
          {/*<Menu.Item
            name="home"
            active={activeItem === "home"}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name="messages"
            active={activeItem === "messages"}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name="friends"
            active={activeItem === "friends"}
            onClick={this.handleItemClick}
          />*/}
          <Menu.Menu position="right">
            {this.hamburger()}
            {this.searchbar()}
            {this.loggedinLinks()}
          </Menu.Menu>
        </Menu>
        <VerticalSidebar visible={this.state.visible} />
        {/* <Nav tags={this.state.tags} compare={this.state.compare} /> */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(Header);
