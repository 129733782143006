import axios from "axios";

function create(pname, description, links, token) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };

  const body = {
    name: pname,
    description: description,
    links: links
  };

  return axios
    .post(process.env.REACT_APP_BACKEND_URL + "/api/v1/properties", body, auth)
    .then(result => {});
}

function bulk_create(properties, token) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };

  const body = {
    properties: properties
  };

  return axios
    .post(
      process.env.REACT_APP_BACKEND_URL + "/api/v1/bulk_properties",
      body,
      auth
    )
    .then(result => {});
}

function index(username, token) {
  //const token_val = token ? "Token " + token : null;

  const auth = {
    headers: { Authorization: "Token " + token }
  };
  return axios.get(
    process.env.REACT_APP_BACKEND_URL +
      "/api/v1/users/" +
      username +
      "/properties",
    auth
  );
}

function find(id, token, username) {
  //const token_val = token ? "Token " + token : null;

  const auth = {
    headers: { Authorization: "Token " + token }
  };
  return axios.get(
    process.env.REACT_APP_BACKEND_URL +
      "/api/v1/users/" +
      username +
      "/property/" +
      id,
    auth
  );
}

function update(pname, description, links, token, id) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };

  const body = {
    name: pname,
    description: description,
    links: links
  };

  return axios.put(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/property/" + id,
    body,
    auth
  );
}

function destroy(id, token) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };

  return axios.delete(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/property/" + id,
    auth
  );
}

export const propertyService = {
  create,
  index,
  find,
  update,
  destroy,
  bulk_create
};
