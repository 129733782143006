import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment
} from "semantic-ui-react";
import { default as Head } from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import { Icon } from "semantic-ui-react";
import "../login/login.css";
import { userService } from "../../../services";
import "../login/login.css";

class SignupForm extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      redirectTo: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    // TODO - validate!
    userService.signup(this.state.email, this.state.password).then(response => {
      if (!response.data.errmsg) {
        console.log("youre good");
        this.setState({
          redirectTo: "/login"
        });
      } else {
        console.log("Already exists");
      }
    });
  }
  render() {
    if (this.state.redirectTo) {
      return <Redirect to={{ pathname: this.state.redirectTo }} />;
    }
    return (
      <div className="login-form">
        <style>
          {`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}
        </style>
        <Head />
        <Grid
          className="row"
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450, marginTop: -100 }}>
            <Header
              as="h2"
              color="orange"
              className="login-header"
              textAlign="center"
            >
              Sign Up
            </Header>
            <Form size="large" onSubmit={this.handleSubmit}>
              <Segment stacked>
                <Form.Input
                  icon="user"
                  iconPosition="left"
                  label="Email"
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <Form.Input
                  icon="lock"
                  iconPosition="left"
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
                <Form.Input
                  icon="lock"
                  iconPosition="left"
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={this.state.confirmPassword}
                  onChange={this.handleChange}
                />

                <Button color="orange inverted" fluid size="large">
                  Signup
                </Button>
                <div className="google">
                  <a href="/auth/google" className="button google-button">
                    <Icon
                      name="google"
                      className="button-label vertical-super google-icon"
                    />
                    <span className="button-label vertical-super">
                      Sign up with Google
                    </span>
                  </a>
                </div>
              </Segment>
            </Form>
            <Message>
              <Link to="/login" className="loginp-link">
                Login
              </Link>
              <Link to="/forgotpassword" className="loginp-link">
                Forgot Password?
              </Link>
            </Message>
          </Grid.Column>
        </Grid>
        <Footer />
      </div>
    );
  }
}

export default SignupForm;
