import React, { Component } from "react";
import Header from "../../header/header";
import Sidebar from "../../sidebar/sidebar";
import { Button, Form } from "semantic-ui-react";
import { propertyService } from "../../../services/property.service";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Grid, Segment } from "semantic-ui-react";
import Footer from "../../../components/footer/footer";
import { Icon } from "semantic-ui-react";

class newProperty extends Component {
  state = {
    properties: [{ name: "", description: "" }],
    links: [],
    redirect: false
  };

  handleSubmit = e => {
    e.preventDefault();
    propertyService
      .bulk_create(this.state.properties, this.props.token)
      .then(response => {
        return this.setState({ redirect: true });
      });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleDescChange = (index, event) => {
    let newArray = [...this.state.properties];
    newArray[index].description = event.target.value;

    this.setState({
      properties: newArray
    });
  };

  handleNameChange = (index, event) => {
    let newArray = [...this.state.properties];
    newArray[index].name = event.target.value;

    this.setState({
      properties: newArray
    });
  };

  handleAddElement = () => {
    let newProperties = [
      ...this.state.properties,
      { name: "", description: "" }
    ];
    this.setState({
      properties: newProperties
    });
  };

  handleRemoveElement = id => {
    let newArray = this.state.properties.map((el, index) => {
      if (id === "element_" + index) {
        return undefined;
      }
      return el;
    });
    let newProperties = newArray.filter(function(el) {
      return el !== undefined;
    });
    this.setState({
      properties: newProperties
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/admin/properties/" />;
    }
    return (
      <div className="properties">
        <Header />
        <Grid id="uigrid">
          <Sidebar item="properties" />
          <Grid.Column stretched width={12}>
            <Segment>
              <div className="content section">
                <Form onSubmit={this.handleSubmit}>
                  <div>
                    {this.state.properties.map((element, index) => (
                      <div key={index}>
                        <div>
                          <Form.Field className="form-field">
                            <label className="form-label">Name</label>
                            <Form.Input
                              placeholder="Name"
                              value={this.state.properties[index].name}
                              name="name"
                              onChange={opts =>
                                this.handleNameChange(index, opts)
                              }
                              required
                            />
                          </Form.Field>
                          <Form.Field className="form-field">
                            <label className="form-label">Description</label>
                            <Form.TextArea
                              placeholder="Description"
                              value={this.state.properties[index].description}
                              name="description"
                              onChange={opts =>
                                this.handleDescChange(index, opts)
                              }
                              required
                            />
                          </Form.Field>
                        </div>
                        <button
                          type="button"
                          onClick={() =>
                            this.handleRemoveElement("element_" + index)
                          }
                          className="small del-link"
                        >
                          <Icon name="delete" />
                          Remove
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={this.handleAddElement}
                      className="small add-link"
                    >
                      <Icon name="add" />
                      Add Another
                    </button>
                  </div>

                  {/*<Form.Field className="form-field">
                    <label className="form-label">Links</label>
                    <ArrayForm
                      array={this.state.links}
                      onStateChange={this.sync}
                      handleRemoveElement={opts =>
                        this.handleRemoveElement("links", opts)
                      }
                      handleArrayChange={opts =>
                        this.handleArrayChange("links", opts)
                      }
                      handleAddElement={opts => this.handleAddElement("links")}
                    />
                    </Form.Field>*/}
                  <Button type="submit">Submit</Button>
                </Form>
              </div>
            </Segment>
          </Grid.Column>
        </Grid>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(newProperty);
