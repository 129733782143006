import React, { Component } from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";

import Cheatsheets from "./components/admin/cheatsheets/index";
import showCheatsheet from "./components/admin/cheatsheets/show";
import pShowSyntaxes from "./components/admin/cheatsheets/pshow";

import editCategory from "./components/admin/category/edit";
import newCategory from "./components/admin/category/new";
import Categories from "./components/admin/category/index";
import editCompare from "./components/admin/compare/edit";
import newCompare from "./components/admin/compare/new";
import Compare from "./components/admin/compare/index";

import Languages from "./components/admin/language/index";
import newLanguage from "./components/admin/language/new";
import EditLanguage from "./components/admin/language/edit";
import Language from "./components/admin/language/show";
import newProperty from "./components/admin/property/new";
import EditProperty from "./components/admin/property/edit";
import Properties from "./components/admin/property/index";
import Syntaxes from "./components/admin/syntax/index";
import ShowSyntaxes from "./components/admin/syntax/show";

import newSyntax from "./components/admin/syntax/new";
import EditSyntax from "./components/admin/syntax/edit";
import Signup from "./components/authentication/signup/signup";
import Login from "./components/authentication/login/login";
import Unauthorized from "./components/errorPages/unauthorized";
import User from "./components/users/user";
import UserLanguages from "./components/users/languages";
import UserCategories from "./components/users/categories";
import UserProperty from "./components/users/property";
import Settings from "./components/admin/settings/index";
import ForgotPassword from "./components/authentication/forgotPassword/forgotPassword";
import newPassword from "./components/authentication/newPassword/newPassword";

import Root from "./components/pages/root";

//import Property from "./components/admin/property/property";
import "./style.css";

import { connect } from "react-redux";

export const RemountingRoute = props => {
  const { component, ...other } = props;
  const Component = component;
  return (
    <Route
      {...other}
      render={p => (
        <Component
          key={p.location.pathname + p.location.search}
          history={p.history}
          location={p.location}
          match={p.match}
        />
      )}
    />
  );
};

const ProtectedRoute = ({ component: Comp, loggedIn, path, ...rest }) => {
  return (
    <Route
      exact
      path={path}
      {...rest}
      render={props => {
        return loggedIn ? (
          <Comp {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login"
            }}
          />
        );
      }}
    />
  );
};

class App extends Component {
  state = {};
  loggedIn = () => {
    if (
      this.props.email == null ||
      this.props.token == null ||
      this.props.timestamp - new Date() < 60 * 60 * 24 * 5
    ) {
      return false;
    }
    return true;
  };

  render() {
    return (
      <BrowserRouter>
        <div className="app">
          <Switch>
            <ProtectedRoute
              path="/admin/categories/new"
              loggedIn={this.loggedIn()}
              component={newCategory}
            />
            <ProtectedRoute
              path="/admin/categories/:id/edit"
              loggedIn={this.loggedIn()}
              component={editCategory}
            />
            <ProtectedRoute
              path="/admin/categories/"
              loggedIn={this.loggedIn()}
              component={Categories}
            />
            <ProtectedRoute
              path="/admin/cheatsheets/:language_id/"
              loggedIn={this.loggedIn()}
              component={showCheatsheet}
            />
            <ProtectedRoute
              path="/admin/cheatsheets/"
              loggedIn={this.loggedIn()}
              component={Languages}
            />
            <ProtectedRoute
              path="/admin/compare/new"
              loggedIn={this.loggedIn()}
              component={newCompare}
            />
            <ProtectedRoute
              path="/admin/compare/:id/edit"
              loggedIn={this.loggedIn()}
              component={editCompare}
            />
            <ProtectedRoute
              path="/admin/compare/"
              loggedIn={this.loggedIn()}
              component={Compare}
            />
            <ProtectedRoute
              path="/admin/languages/new"
              loggedIn={this.loggedIn()}
              component={newLanguage}
            />
            <ProtectedRoute
              path="/admin/languages/:id/edit"
              loggedIn={this.loggedIn()}
              component={EditLanguage}
            />
            <ProtectedRoute
              path="/admin/languages/:id"
              loggedIn={this.loggedIn()}
              component={Language}
            />
            <ProtectedRoute
              path="/admin/languages"
              loggedIn={this.loggedIn()}
              component={Languages}
            />
            <ProtectedRoute
              path="/admin/properties/new"
              loggedIn={this.loggedIn()}
              component={newProperty}
            />
            <ProtectedRoute
              path="/admin/properties/:id/edit"
              loggedIn={this.loggedIn()}
              component={EditProperty}
            />
            <ProtectedRoute
              path="/admin/properties"
              loggedIn={this.loggedIn()}
              component={Properties}
            />
            <ProtectedRoute
              path="/admin/syntaxes/new"
              loggedIn={this.loggedIn()}
              component={newSyntax}
            />
            <ProtectedRoute
              path="/admin/syntaxes/:id/edit"
              loggedIn={this.loggedIn()}
              component={EditSyntax}
            />
            <ProtectedRoute
              path="/admin/syntaxes/:language_id"
              loggedIn={this.loggedIn()}
              component={ShowSyntaxes}
            />
            <ProtectedRoute
              path="/admin/syntax/:syntax_id"
              loggedIn={this.loggedIn()}
              component={pShowSyntaxes}
            />
            <ProtectedRoute
              path="/admin/syntaxes"
              loggedIn={this.loggedIn()}
              component={Syntaxes}
            />
            <ProtectedRoute
              path="/admin/settings"
              loggedIn={this.loggedIn()}
              component={Settings}
            />
            <RemountingRoute
              path="/users/:username/languages/:language_id"
              component={UserLanguages}
            />
            <RemountingRoute
              path="/users/:username/categories/:category_id"
              component={UserCategories}
            />
            <RemountingRoute
              path="/users/:username/properties/:property_id"
              component={UserProperty}
            />
            <Route path="/users/:username" component={User} />
            <Route
              path="/auth/google"
              component={() => {
                window.location =
                  process.env.REACT_APP_BACKEND_URL +
                  "/api/v1/users/auth/google/";
                return null;
              }}
            />
            <Route exact path="/unauthorized" component={Unauthorized} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/change_password" component={newPassword} />
            <Route exact path="/login" component={Login} />
            <RemountingRoute
              path="/"
              loggedIn={this.loggedIn()}
              component={Root}
            />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    timestamp: state.authentication.timestamp
  };
};

const mapDispatchToProps = dispatch => {};

export default connect(mapStateToProps, mapDispatchToProps)(App);
