import React, { Component } from "react";
import Select from "react-select";
import Header from "../../header/header";
import Sidebar from "../../sidebar/sidebar";
import { Button, Form } from "semantic-ui-react";
import { syntaxService } from "../../../services/syntax.service";
import { propertyService } from "../../../services/property.service";
import { connect } from "react-redux";
import ArrayForm from "../../array/array";
import { Redirect } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import createCodeEditorPlugin from "draft-js-code-editor-plugin";
import { Grid, Segment } from "semantic-ui-react";
import Footer from "../../../components/footer/footer";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import queryString from "query-string";
import { Tab, Input, TextArea, Icon } from "semantic-ui-react";
import { Header as SemanticHeader } from "semantic-ui-react";
import CreatableSelect from "react-select";

class newSyntax extends Component {
  state = {
    language_id: "",
    property_id: "",
    version_id: "",
    title: "",
    property_name: "",
    parent_property_ids: [],
    parent_properties_ids: [],
    wysiwyg: EditorState.createEmpty(),
    links: [],
    cheetsheet_links: [],
    stackoverflow_links: [],
    tutorial_links: [],
    docs_links: [],
    languages: [],
    properties: [],
    parent_properties: [],
    children_properties: [],
    plugins: [createCodeEditorPlugin()],
    redirect: false
  };

  componentDidMount() {
    var query = queryString.parse(this.props.location.search);
    if (query.language_id) {
      this.setState({ language_id: query.language_id });
    }

    propertyService
      .index(this.props.username, this.props.token)
      .then(response => {
        let newProperties = response.data;
        this.setState({ properties: newProperties });
      });
  }

  panes = [
    {
      menuItem: "Tab 1",
      render: () => (
        <Tab.Pane attached={false}>
          {" "}
          <ArrayForm
            array={this.state.tutorial_links}
            onStateChange={this.sync}
            handleRemoveElement={opts =>
              this.handleRemoveElement("tutorial_links", opts)
            }
            handleArrayChange={opts =>
              this.handleArrayChange("tutorial_links", opts)
            }
            handleAddElement={opts => this.handleAddElement("tutorial_links")}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: "Tab 2",
      render: () => (
        <Tab.Pane attached={false}>
          {" "}
          <ArrayForm
            array={this.state.tutorial_links}
            onStateChange={this.sync}
            handleRemoveElement={opts =>
              this.handleRemoveElement("tutorial_links", opts)
            }
            handleArrayChange={opts =>
              this.handleArrayChange("tutorial_links", opts)
            }
            handleAddElement={opts => this.handleAddElement("tutorial_links")}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: "Tab 3",
      render: () => (
        <Tab.Pane attached={false}>
          {" "}
          <ArrayForm
            array={this.state.tutorial_links}
            onStateChange={this.sync}
            handleRemoveElement={opts =>
              this.handleRemoveElement("tutorial_links", opts)
            }
            handleArrayChange={opts =>
              this.handleArrayChange("tutorial_links", opts)
            }
            handleAddElement={opts => this.handleAddElement("tutorial_links")}
          />
        </Tab.Pane>
      )
    }
  ];

  goBack = e => {
    this.props.history.goBack();
  };

  handleSubmit = e => {
    e.preventDefault();
    const contentState = this.state.wysiwyg.getCurrentContent();
    syntaxService
      .create(
        this.state.language_id,
        this.state.property_id,
        this.state.version_id,
        this.state.title,
        JSON.stringify(convertToRaw(contentState)),
        this.state.cheetsheet_links,
        this.state.stackoverflow_links,
        this.state.tutorial_links,
        this.state.docs_links,
        this.state.parent_properties,
        this.state.property_name,
        this.props.token
      )
      .then(response => {
        return this.setState({ redirect: true });
      });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleChangeWysiwyg = value => {
    this.setState({
      wysiwyg: value
    });
  };

  handleArrayChange = (element, event) => {
    let newArray = [...this.state[element]];
    let id = event.target.id;
    newArray[id.replace("element_", "")] = event.target.value;
    newArray = newArray.filter(function(element) {
      return element !== undefined;
    });
    let newHash = {};
    newHash[element] = newArray;
    this.setState(newHash);
  };

  handleAddElement = element => {
    let newHash = {};
    newHash[element] = [...this.state[element], ""];
    this.setState(newHash);
  };

  handleRemoveElement = (element, id) => {
    let newArray = this.state[element].map((el, index) => {
      if (id === "element_" + index) {
        return undefined;
      }
      return el;
    });
    newArray = newArray.filter(function(el) {
      return el !== undefined;
    });
    let newHash = {};
    newHash[element] = newArray;
    this.setState(newHash);
  };

  handleSelect = (field, opts) => {
    let newfield = {};
    newfield[field] = opts.value;
    this.setState(newfield);
  };

  handleMultiSelect = (field, opts) => {
    let new_parent_properties = [];
    new_parent_properties = opts.map(opt => opt["value"]);
    this.setState({
      parent_properties: new_parent_properties,
      parent_properties_ids: []
    });
  };

  handleInputChange = (inputValue, actionMeta) => {
    console.group("Input Changed");
    let newHash = {};
    let property = { name: inputValue };
    newHash["properties"] = [...this.state.properties, property];
    this.setState(newHash);

    console.log(inputValue);
    console.log(this.state.properties);

    // console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  handlePnameSelect = (field, opts) => {
    let newfield = {};
    newfield[field] = opts.value;
    newfield["property_id"] = false;
    this.setState(newfield);
  };

  render() {
    if (this.state.redirect) {
      let url = "/admin/cheatsheets/" + this.state.language_id;
      return <Redirect to={url} />;
    }

    let panes = [
      {
        menuItem: "Tutorials",
        render: () => (
          <Tab.Pane attached={false}>
            <ArrayForm
              array={this.state.tutorial_links}
              onStateChange={this.sync}
              handleRemoveElement={opts =>
                this.handleRemoveElement("tutorial_links", opts)
              }
              handleArrayChange={opts =>
                this.handleArrayChange("tutorial_links", opts)
              }
              handleAddElement={opts => this.handleAddElement("tutorial_links")}
            />
          </Tab.Pane>
        )
      },
      {
        menuItem: "Cheetsheets",
        render: () => (
          <Tab.Pane attached={false}>
            <ArrayForm
              array={this.state.cheetsheet_links}
              onStateChange={this.sync}
              handleRemoveElement={opts =>
                this.handleRemoveElement("cheetsheet_links", opts)
              }
              handleArrayChange={opts =>
                this.handleArrayChange("cheetsheet_links", opts)
              }
              handleAddElement={opts =>
                this.handleAddElement("cheetsheet_links")
              }
            />
          </Tab.Pane>
        )
      },
      {
        menuItem: "Stackoverflow",
        render: () => (
          <Tab.Pane attached={false}>
            <ArrayForm
              array={this.state.stackoverflow_links}
              onStateChange={this.sync}
              handleRemoveElement={opts =>
                this.handleRemoveElement("stackoverflow_links", opts)
              }
              handleArrayChange={opts =>
                this.handleArrayChange("stackoverflow_links", opts)
              }
              handleAddElement={opts =>
                this.handleAddElement("stackoverflow_links")
              }
            />
          </Tab.Pane>
        )
      },
      {
        menuItem: "Documentation",
        render: () => (
          <Tab.Pane attached={false}>
            <ArrayForm
              array={this.state.docs_links}
              onStateChange={this.sync}
              handleRemoveElement={opts =>
                this.handleRemoveElement("docs_links", opts)
              }
              handleArrayChange={opts =>
                this.handleArrayChange("docs_links", opts)
              }
              handleAddElement={opts => this.handleAddElement("docs_links")}
            />
          </Tab.Pane>
        )
      }
    ];

    return (
      <div>
        <Header />
        <div className="section_content">
          <div className="">
            <a onClick={this.goBack} href="#" class="back-link">
              <Icon name="angle left" /> Go Back
            </a>
          </div>
        </div>
        <Form className="form" onSubmit={this.handleSubmit}>
          <SemanticHeader as="h4" content="Add code" textAlign="left" />

          <Form.Group widths="equal">
            <Form.Field
              fluid
              placeholder="Title"
              value={this.state.title}
              label="Title"
              name="title"
              onChange={this.handleChange}
              required
              id="form-input-control-error-title"
              control={Input}
            />
          </Form.Group>

          <div>
            <div>
              <label className="form-label">Code attribute*</label>
            </div>

            <CreatableSelect
              isClearable
              id="form-input-control-error-name"
              onChange={opts => this.handlePnameSelect("property_name", opts)}
              onInputChange={this.handleInputChange}
              options={this.state.properties.map(property => {
                return { label: property.name, value: property.name };
              })}
              label="Code attribute"
              placeholder="Code Attribute"
              value={this.state.properties.map(property => {
                let propState = false;
                if (this.state.property_name == property.name) {
                  propState = true;
                } else if (
                  this.state.property_id == property._id &&
                  this.state.property_id != false
                ) {
                  propState = true;
                }
                return propState
                  ? { label: property.name, value: property.name }
                  : "";
              })}
              name="property_name"
              required
            />
          </div>

          <div>
            <div>
              <label className="form-label">Parent properties(optional)*</label>
            </div>
            <Form.Field className="form-field">
              <CreatableSelect
                isClearable
                id="form-input-control-error-name"
                options={this.state.properties.map(property => {
                  return { label: property.name, value: property.name };
                })}
                isMulti
                onInputChange={this.handleInputChange}
                placeholder="Parent properties(optional)"
                value={this.state.properties.map(property => {
                  let propState = false;
                  if (this.state.parent_properties.includes(property.name)) {
                    propState = true;
                  } else if (
                    this.state.parent_properties_ids.includes(property._id) &&
                    this.state.parent_properties_ids != []
                  ) {
                    propState = true;
                  }

                  return propState
                    ? { label: property.name, value: property.name }
                    : "";
                })}
                name="parent_properties"
                onChange={opts =>
                  this.handleMultiSelect("parent_properties", opts)
                }
                required
              />
            </Form.Field>
          </div>

          <Form.Group widths="equal">
            <Form.Field className="form-field">
              <label className="form-label">Description*</label>
              <Editor
                editorState={this.state.wysiwyg}
                toolbarClassName="toolbarClassName"
                wrapperClassName="editorWrapper"
                editorClassName="wysiwyg"
                onEditorStateChange={this.handleChangeWysiwyg}
                toolbar={{
                  inline: { inDropdown: true },
                  blockType: {
                    inDropdown: false,
                    options: ["Code", "Blockquote", "Normal", "H1", "H2", "H3"]
                  },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: false },
                  link: { inDropdown: true },
                  history: { inDropdown: true }
                }}
                plugins={this.state.plugins}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field className="form-field">
              <label className="form-label">Links</label>
              <Tab menu={{ pointing: true }} panes={panes} />
            </Form.Field>
          </Form.Group>

          <Form.Field
            id="form-button-control-public"
            control={Button}
            content="Submit"
            color="violet"
            position="right"
            textAlign="right"
            float="right"
            right
            label=""
          />
        </Form>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(newSyntax);
