import axios from "axios";

function create(pname, description, token, email) {
  const auth = {
    headers: { Authorization: "Token " + token, email: email }
  };

  return axios
    .post(
      process.env.REACT_APP_BACKEND_URL + "/api/v1/language_tags",
      {
        name: pname,
        description: description,
        comparison: false,
        languages: []
      },
      auth
    )
    .then(result => {});
}

function compare_create(pname, description, cheatsheets, token, email) {
  const auth = {
    headers: { Authorization: "Token " + token, email: email }
  };

  return axios
    .post(
      process.env.REACT_APP_BACKEND_URL + "/api/v1/language_tags",
      {
        name: pname,
        description: description,
        comparison: true,
        languages: cheatsheets
      },
      auth
    )
    .then(result => {});
}

function index(username, token) {
  const token_val = token ? "Token " + token : null;
  const auth = {
    headers: { Authorization: token_val }
  };
  const url =
    process.env.REACT_APP_BACKEND_URL +
    "/api/v1/users/" +
    username +
    "/language_tags";
  return axios.get(url, auth);
}

function compare(username, token) {
  const token_val = token ? "Token " + token : null;
  const auth = {
    headers: { Authorization: token_val }
  };
  const url =
    process.env.REACT_APP_BACKEND_URL +
    "/api/v1/users/" +
    username +
    "/compare";
  return axios.get(url, auth);
}

function find(id, username, token) {
  const token_val = token ? "Token " + token : null;
  const auth = {
    headers: { Authorization: token_val }
  };
  return axios.get(
    process.env.REACT_APP_BACKEND_URL +
      "/api/v1/users/" +
      username +
      "/language_tag/" +
      id,
    auth
  );
}

function update(pname, description, token, id) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };

  return axios.put(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/language_tag/" + id,
    { name: pname, description: description, languages: [] },
    auth
  );
}

function compare_update(pname, description, languages, token, id) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };

  return axios.put(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/language_tag/" + id,
    { name: pname, description: description, languages: languages },
    auth
  );
}

function destroy(id, token) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };

  return axios.delete(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/language_tag/" + id,
    auth
  );
}

export const categoryService = {
  create,
  index,
  find,
  update,
  destroy,
  compare,
  compare_update,
  compare_create
};
