import React, { Component } from "react";
import Header from "../../components/header/header";
import Nav from "../../components/nav/nav";
import Footer from "../.././components/footer/footer";
import { mainService } from "../../services/main.service";
import { connect } from "react-redux";
import "../../style.css";
import { categoryService } from "../../services";
import { Link } from "react-router-dom";
import Section from "../section/section";
import { Grid, Segment } from "semantic-ui-react";
import Carousel from "semantic-ui-carousel-react";
import { Image, Button } from "semantic-ui-react";
import "./root.css";
import { Divider } from "semantic-ui-react";

class Root extends Component {
  state = {
    languages: [],
    categories: [],
    tags: [],
    compare: [],
    users: []
  };

  elements = [
    {
      render: () => {
        return (
          <Segment>
            <Grid columns={2} relaxed="very">
              <Grid.Column className="center">
                <h1 className="slider-link">Never forget</h1>
                <div>
                  Never forget a single link or a method that you have used.
                  Document as you learn
                </div>
              </Grid.Column>
              <Grid.Column className="center">
                <h1 className="slider-link">Categorize your learning</h1>
                <div>
                  Category wise save your newly learned tools languages or
                  methods
                </div>
              </Grid.Column>
            </Grid>

            <Divider vertical>And</Divider>
          </Segment>
        );
      }
    },
    {
      render: () => {
        return (
          <Segment>
            <Grid columns={2} relaxed="very">
              <Grid.Column className="center">
                <h1 className="slider-link">Cheetsheet</h1>
                <div>
                  Create a cheetsheet with complete method documentation
                </div>
              </Grid.Column>
              <Grid.Column className="center">
                <h1 className="slider-link">Password Protect</h1>
                <div>Password protect your private cheetsheet</div>
              </Grid.Column>
            </Grid>

            <Divider vertical>And</Divider>
          </Segment>
        );
      }
    },
    {
      render: () => {
        return (
          <Segment>
            <Grid columns={2} relaxed="very">
              <Grid.Column className="center">
                <h1 className="slider-link">Compare</h1>
                <div>
                  Compare similiar properties of different languages or tools
                </div>
              </Grid.Column>
              <Grid.Column className="center">
                <h1 className="slider-link">Change Order</h1>
                <div>
                  Change which language tool you want to compare with using a
                  drag and drop option
                </div>
              </Grid.Column>
            </Grid>

            <Divider vertical>And</Divider>
          </Segment>
        );
      }
    },
    {
      render: () => {
        return (
          <Segment>
            <Grid columns={2} relaxed="very">
              <Grid.Column className="center">
                <h1 className="slider-link">Make profile private</h1>
                <div>Use this small tool privately</div>
              </Grid.Column>
              <Grid.Column className="center">
                <h1 className="slider-link">Check for other cheetsheets</h1>
                <div>Learn using others cheetsheet</div>
              </Grid.Column>
            </Grid>

            <Divider vertical>And</Divider>
          </Segment>
        );
      }
    }
  ];

  componentDidMount() {
    this.getDataFromDb();
    return this.props.token ? this.getTagsFromDB() : "";
  }

  getTagsFromDB = () => {
    categoryService.index(this.props.username, this.props.token).then(res => {
      this.setState({
        tags: res.data
      });
    });

    categoryService.compare(this.props.username, this.props.token).then(res => {
      this.setState({
        compare: res.data
      });
    });
  };

  getDataFromDb = () => {
    mainService.categories(this.props.username).then(res => {
      console.log("category data", res);

      this.setState({
        categories: res.data
      });
    });
    mainService.languages(this.props.username).then(res => {
      console.log("language data", res);

      this.setState({
        languages: res.data
      });
    });
    mainService.users(this.props.username).then(res => {
      console.log("data", res);
      this.setState({
        users: res.data
      });
    });
  };

  //data
  data = () => {
    let data =
      this.state.tags.length === 0 ? (
        <div>
          <Header
            _logout={this._logout}
            loggedIn={this.state.loggedIn}
            filter_data={this.filter_data}
            search={false}
          />
          <React.Fragment>
            {this.props.token ? (
              <div className="section create">
                <div>
                  <Link to="/admin/categories">
                    Please add tags in the admin panel
                  </Link>
                  <Section
                    heading="My Tags"
                    data={this.state.categories}
                    type="categories"
                    user={this.props.username}
                  />
                  <Section
                    heading="My Languages"
                    data={this.state.languages}
                    type="languages"
                    user={this.props.username}
                  />
                  <Section
                    heading="Explore Users"
                    data={this.state.users}
                    type="users"
                    user={this.props.username}
                  />
                </div>
              </div>
            ) : (
              <div className="home-section">
                <Carousel
                  elements={this.elements}
                  duration={5000}
                  animation="slide right"
                  showNextPrev={false}
                  showIndicators={false}
                />
                <div className="columns">
                  <h1 className="explore">Explore</h1>
                  <Grid columns={3} divided>
                    <Grid.Row stretched>
                      <Grid.Column>
                        <h3 className="slider-link">Categorywise Compare</h3>
                        <Segment>
                          {this.state.categories.map(category => {
                            return (
                              <Link
                                to={
                                  "/users/" +
                                  category.username +
                                  "/categories/" +
                                  category.id
                                }
                                key={category.id}
                              >
                                <h3 className="slider-link">{category.name}</h3>

                                <div className="hp-description">
                                  {category.description.substring(0, 120)}..
                                </div>
                                <Grid columns={3} divided>
                                  {category.languages.map(lang => {
                                    return (
                                      <Grid.Column className="grid-featured">
                                        {lang}
                                      </Grid.Column>
                                    );
                                  })}
                                </Grid>
                                <div>
                                  <Link
                                    to={
                                      "/users/" +
                                      category.username +
                                      "/categories/" +
                                      category.id
                                    }
                                    key={category.id}
                                  >
                                    - {category.username}
                                  </Link>
                                </div>
                              </Link>
                            );
                          })}
                        </Segment>
                      </Grid.Column>

                      <Grid.Column>
                        <h3 className="slider-link">Languages</h3>
                        {this.state.languages.map(language => {
                          return (
                            <Segment>
                              <Link
                                to={
                                  "/users/" +
                                  language.username +
                                  "/languages/" +
                                  language.id
                                }
                                key={language._id}
                              >
                                <h3 className="slider-link">{language.name}</h3>
                              </Link>
                              <div className="hp-description">
                                {language.description.substring(0, 120)}..
                              </div>
                              <div>
                                <Link
                                  to={
                                    "/users/" +
                                    language.username +
                                    "/categories/" +
                                    language.tag
                                  }
                                  key={language._id}
                                >
                                  - {language.username}
                                </Link>
                              </div>
                            </Segment>
                          );
                        })}
                      </Grid.Column>
                      <Grid.Column>
                        <h3 className="slider-link">Featured Users</h3>
                        {this.state.users.map(user => {
                          return (
                            <Segment>
                              <Link
                                to={
                                  "/users/" +
                                  user.name +
                                  "/categories/" +
                                  user.category_id
                                }
                                key={user.id}
                              >
                                <h3 className="slider-link">{user.name}</h3>
                              </Link>
                              <div className="hp-description">
                                {user.description.substring(0, 120)}..
                              </div>
                            </Segment>
                          );
                        })}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>

                <div className="columns learn-column">
                  <h1 className="learn">Learn</h1>
                  <Grid columns={1} divided>
                    <Grid.Row stretched>
                      <Grid.Column className="horizontal-padding">
                        <Segment>
                          <h3 className="slider-link">Syntax</h3>
                          <div className="hp-description">
                            Learning to code needs hours of practice and focus.
                            Don't let the syntax hold you back
                          </div>
                        </Segment>
                      </Grid.Column>
                      <Grid.Column className="horizontal-padding">
                        <Segment>
                          <h3 className="slider-link">Focus on concepts</h3>
                          <div className="hp-description">
                            Learn to code and focus more on the conceptual
                            learning. Keep all the syntax here
                          </div>
                        </Segment>
                      </Grid.Column>
                      <Grid.Column className="horizontal-padding">
                        <Segment>
                          <h3 className="slider-link">Polyglot</h3>
                          <div className="hp-description">
                            As technology evolves it becomes harder and harder
                            to keep up esp with similar concepts with multiple
                            implementations. Compare these similar
                            implementations
                          </div>
                        </Segment>
                      </Grid.Column>
                      <Grid.Column className="horizontal-padding">
                        <Segment>
                          <h3 className="slider-link">Help others learn</h3>
                          <div className="hp-description">
                            Create a cheetsheet and share it privately or
                            publicaly. Share what you have learn here direclty
                            with your friends or followers
                          </div>
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>

                {/*<Section
                  heading="Explore Users"
                  data={this.state.users}
                  type="users"
                  user="not"
                />
                <Section
                  heading="Explore Categories"
                  data={this.state.categories}
                  type="categories"
                  user="not"
                />
                <Section
                  heading="Explore Languages"
                  data={this.state.languages}
                  type="languages"
                  user="not"
                />*/}
              </div>
            )}
          </React.Fragment>
          <Footer />
        </div>
      ) : (
        <div>
          <Header
            _logout={this._logout}
            loggedIn={this.state.loggedIn}
            filter_data={this.filter_data}
            search={false}
          />
          <Nav
            tags={this.state.tags}
            user={this.props.username}
            compare={this.state.compare}
          />
          <div className="home-section">
            <Section
              heading="My Language Categories"
              data={this.state.categories}
              type="categories"
              user={this.props.username}
            />
            <Section
              heading="My Languages"
              data={this.state.languages}
              type="languages"
              user={this.props.username}
            />
            <Section
              heading="Explore Users"
              data={this.state.users}
              type="users"
              user={this.props.username}
            />
          </div>
          <Footer />
        </div>
      );
    return data;
  };

  render() {
    return <div className="App">{this.data()}</div>;
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(Root);
