import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import "./array.css";

class ArrayForm extends Component {
  render() {
    return (
      <div>
        {this.props.array.map((element, index) => (
          <div className="element array-link" key={index}>
            <input
              type="text"
              placeholder={"link"}
              value={element}
              id={"element_" + index}
              onChange={this.props.handleArrayChange}
            />
            <button
              type="button"
              onClick={() => this.props.handleRemoveElement("element_" + index)}
              className="small del-link"
            >
              <Icon name="delete" />
              Delete Link
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={this.props.handleAddElement}
          className="small add-link"
        >
          <Icon name="add" />
          Add Link
        </button>
      </div>
    );
  }
}

export default ArrayForm;
