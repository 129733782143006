import axios from "axios";

function signup(email, password) {
  return axios
    .post(process.env.REACT_APP_BACKEND_URL + "/api/v1/users/signup", {
      email: email,
      password: password
    })
    .then(response => {
      return response;
    });
}

function login(email, password) {
  return axios.post(process.env.REACT_APP_BACKEND_URL + "/api/v1/users/login", {
    email: email,
    password: password
  });
}

function logout(token) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };

  return axios
    .post(process.env.REACT_APP_BACKEND_URL + "/api/v1/users/logout", {}, auth)
    .then(result => {});
}

function google() {
  return axios.get(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/users/auth/google",
    {}
  );
}

function forgot_password(email) {
  return axios.post(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/users/forgot_password",
    {
      email: email
    }
  );
}

function change_password(password, token) {
  return axios.post(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/users/change_password",
    {
      password: password,
      token: token
    }
  );
}

function delete_user(username, token) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };

  return axios.delete(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/users/delete/" + username,
    auth
  );
}

function user_private(username, token, private_param) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };

  const body = {
    private: private_param
  };

  return axios.post(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/users/private/" + username,
    body,
    auth
  );
}

function show(token, username) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };
  return axios.post(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/users/" + username,
    {},
    auth
  );
}

function update(token, username, first_name, last_name, age, gender, bio) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };

  const body = {
    first_name: first_name,
    last_name: last_name,
    age: age,
    gender: gender,
    bio: bio
  };

  return axios.put(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/users/" + username,
    body,
    auth
  );
}

export const userService = {
  login,
  signup,
  logout,
  google,
  forgot_password,
  change_password,
  delete_user,
  user_private,
  show,
  update
};
