import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Label, Checkbox, Item } from "semantic-ui-react";
import "./languageTags.css";

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  margin: "10px",
  display: "block",
  top: "500px",
  left: "0px",
  background: isDragging ? "red" : "#d88c8cfa",
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  overflow: "none",
  border: "5px dotted #ff851b",
  width: "40%",
  textAlign: "center"
});

class LanguageTags extends Component {
  constructor(props) {
    super(props);
    this.languageChange = this.props.languageChange.bind(this);
    this.onDragEnd = this.props.onDragEnd.bind(this);
  }

  checkLanguage = (defaultLanguages, language) => {
    if (defaultLanguages.includes(language)) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.props.onDragEnd}>
        <Droppable
          droppableId="droppable"
          direction="horizontal"
          className="tag-drop"
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {this.props.defaultLanguages.map((language, index) => (
                <Draggable
                  key={language}
                  draggableId={language}
                  index={index}
                  className="language-option"
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Label as="a" image>
                        <Item style={{ marginBottom: "7px" }}>
                          {language.charAt(0).toUpperCase() + language.slice(1)}
                        </Item>
                        <br />
                        <Checkbox
                          slider
                          checked={this.checkLanguage(
                            this.props.languages,
                            language
                          )}
                          onChange={() => this.languageChange(language)}
                        />
                      </Label>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default LanguageTags;
