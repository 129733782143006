import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import "./tableHead.css";

class HeaderCell extends Component {
  state = {};
  render() {
    return (
      <Table.HeaderCell className="table-header">
        {this.props.language}
      </Table.HeaderCell>
    );
  }
}

class TableHead extends Component {
  state = {};
  render() {
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="table-header">Property</Table.HeaderCell>
          {this.props.languages.map(language => {
            return <HeaderCell language={language} key={language} />;
          })}
        </Table.Row>
      </Table.Header>
    );
  }
}

export default TableHead;
