import React, { Component } from "react";
import { Item } from "semantic-ui-react";
import "./footer.css";

class Footer extends Component {
  state = {};
  render() {
    return (
      <div className="footer">
        <Item>
          <Item.Description style={{ float: "right" }}>
            All rights and trademarks reserved
          </Item.Description>
        </Item>
      </div>
    );
  }
}

export default Footer;
