import PropTypes from "prop-types";
import React, { Component } from "react";
import { Icon, Image, Menu, Segment, Sidebar } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { userService } from "../../services/user.service";
import { connect } from "react-redux";

export class VerticalSidebar extends Component {
  state = {
    animation: "scale down",
    direction: "left",
    dimmed: true,
    visible: false
  };

  componentDidMount() {
    this.setState({
      visible: this.props.visible
    });
  }

  handleLogout = () => {
    const token = this.props.token;
    userService.logout(token).then(response => {
      console.log("Logged out");
    });
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    localStorage.removeItem("timestamp");
    localStorage.removeItem("username");
    this.setState({ logged: false });
    window.location.reload();
  };

  render() {
    const { animation, dimmed, direction, visible } = this.state;

    return (
      <Sidebar
        as={Menu}
        animation={animation}
        direction={direction}
        icon="labeled"
        inverted
        vertical
        visible={this.props.visible}
        width="thin"
      >
        <Menu.Item>
          <Link to="/">
            <h2 className="">
              <img src="/logo.svg" alt="CSheet" class="logo" />
            </h2>
          </Link>
          <pre className="white"></pre>
        </Menu.Item>
        <Menu.Item>
          <Link to={"/admin/categories/"}>
            <Icon name="tree" />
            <div>Cheatsheet types</div>
          </Link>
        </Menu.Item>

        <Menu.Item>
          <Link to={"/admin/languages/"}>
            <Icon name="code" />
            <div>Your Cheetsheets</div>
          </Link>
        </Menu.Item>

        <Menu.Item>
          <Link to={"/admin/compare/"}>
            <Icon name="random" />
            <div>Compare(new)</div>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={"/admin/languages/"}>
            <Icon name="home" />
            <div>Projects(new)</div>
          </Link>
        </Menu.Item>
        <Menu.Item as="a">
          <Link to={"/admin/settings"}>
            <Icon name="user" />
            <div>Your Profile</div>
          </Link>
        </Menu.Item>
        <Menu.Item as="a" onClick={() => this.handleLogout()}>
          <Link to={"/admin/languages/"}>
            <Icon name="sign out" />
            <div>Sign Out</div>
          </Link>
        </Menu.Item>
      </Sidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(VerticalSidebar);

// export default class SidebarExampleTransitions extends Component {
//   state = {
//     animation: "scale down",
//     direction: "left",
//     dimmed: true,
//     visible: false
//   };

//   handleNavChange = () =>
//     this.setState(prevState => ({ visible: !prevState.visible }));

//   render() {
//     const { animation, dimmed, direction, visible } = this.state;

//     return (
//       <div>
//         <Button label="Dim Page" onClick={this.handleNavChange} toggle />

//         <Sidebar.Pushable as={Segment}>
//           <VerticalSidebar
//             animation={animation}
//             direction={direction}
//             visible={visible}
//           />
//           <Sidebar.Pusher dimmed={dimmed && visible}>
//             <Segment basic>
//               <Header as="h3">Application Content</Header>
//               <Image src="/images/wireframe/paragraph.png" />
//             </Segment>
//           </Sidebar.Pusher>
//         </Sidebar.Pushable>
//       </div>
//     );
//   }
// }

// import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import "./sidebar.css";
// import { Grid, Menu } from "semantic-ui-react";

// class Sidebar extends Component {
//   handleItemClick = (e, { name }) => this.setState({ activeItem: name });
//   render() {
//     return (
//       <Grid.Column width={4}>
//         <Menu fluid vertical tabular>
//           <Link to="/admin/languages">
//             <Menu.Item
//               name="languages"
//               active={this.props.item === "languages"}
//             >
//               Language/Framework/Tool
//             </Menu.Item>
//           </Link>
//           <Link to="/admin/properties">
//             <Menu.Item
//               name="properties"
//               active={this.props.item === "properties"}
//             >
//               Features/Properties/Methods
//             </Menu.Item>
//           </Link>
//           <Link to="/admin/categories">
//             <Menu.Item
//               name="categories"
//               active={this.props.item === "categories"}
//             >
//               Compare Tags/Categories
//             </Menu.Item>
//           </Link>
//           <Link to="/admin/syntaxes">
//             <Menu.Item name="syntaxes" active={this.props.item === "syntaxes"}>
//               Posts
//             </Menu.Item>
//           </Link>
//           <Link to="/admin/settings">
//             <Menu.Item name="settings" active={this.props.item === "settings"}>
//               Settings
//             </Menu.Item>
//           </Link>
//         </Menu>
//       </Grid.Column>
//     );
//   }
// }
