import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment
} from "semantic-ui-react";
import { default as Head } from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import "../login/login.css";
import { userService } from "../../../services";
import queryString from "query-string";

class newPassword extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      token: "",
      redirectTo: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    var query = queryString.parse(this.props.location.search);
    if (query.token) {
      this.setState({ token: query.token });
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    // TODO - validate!
    userService
      .change_password(this.state.password, this.state.token)
      .then(response => {
        if (!response.data.errmsg) {
          console.log("youre good");
          this.setState({
            redirectTo: "/login"
          });
        } else {
          console.log("Already exists");
        }
      });
  }
  render() {
    if (this.state.redirectTo) {
      return <Redirect to={{ pathname: this.state.redirectTo }} />;
    }
    return (
      <div className="login-form">
        <style>
          {`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}
        </style>
        <Head />
        <Grid
          className="row"
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450, marginTop: -100 }}>
            <Header as="h2" color="teal" textAlign="center">
              Change Password
            </Header>
            <Form size="large" onSubmit={this.handleSubmit}>
              <Segment stacked>
                <Form.Input
                  icon="lock"
                  iconPosition="left"
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
                <Form.Input
                  icon="lock"
                  iconPosition="left"
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={this.state.confirmPassword}
                  onChange={this.handleChange}
                />

                <Button color="teal" fluid size="large">
                  Reset Password
                </Button>
              </Segment>
            </Form>
            <Message>
              Already a member?
              <Link to="/login">Login</Link>
            </Message>
          </Grid.Column>
        </Grid>
        <Footer />
      </div>
    );
  }
}

export default newPassword;
