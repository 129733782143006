import React, { Component } from "react";
import Header from "../../header/header";
import Sidebar from "../../sidebar/sidebar";
import { connect } from "react-redux";
import { syntaxService } from "../../../services/syntax.service";
import { languageService } from "../../../services/language.service";

import { Link } from "react-router-dom";
import { Grid, Segment, Button, List, Icon } from "semantic-ui-react";
import Footer from "../../footer/footer";
import BoxContainer from "./box_container";
// import Footer from "../../../components/footer/footer";

class pShowSyntaxes extends Component {
  state = {
    syntaxes: [],
    name: "",
    language_tags: "",
    description: "",
    source_code_link: "",
    links: [],
    version: "",
    redirect: false,
    categories: [],
    category_ids: [],
    version_name: ""
  };

  handleClick = id => {
    const token = this.props.token;
    syntaxService.destroy(id, token).then(response => {
      let newSyntaxes = this.state.syntaxes.filter(syntax => {
        return syntax._id !== id;
      });
      this.setState({
        syntaxes: newSyntaxes
      });
    });
  };

  componentDidMount() {
    let id = this.props.match.params.syntax_id;

    syntaxService
      .pshow(id, this.props.token, this.props.username)
      .then(response => {
        debugger;
        console.log("lang responsse", response);
        if (response.data !== []) {
          this.setState({
            syntaxes: [response.data["syntaxes"]],
            name: response.data["syntaxes"].name,
            description: response.data["syntaxes"].description,
            source_code_link: response.data["syntaxes"].source_code_link,
            links: response.data["syntaxes"].links,
            version_name: response.data["syntaxes"].version_name,
            category_ids: response.data["syntaxes"].language_tags
          });
        }
      });
  }

  goBack = e => {
    this.props.history.goBack();
  };

  data = syntaxes => {
    let data =
      // this.state.syntaxes.length === 0 ? (
      //   <div>
      //     <h3 className="no-categories">No code to display</h3>
      //   </div>
      // ) : (
      syntaxes.map(syntax => {
        return (
          <div>
            <List.Item key={syntax._id}>
              <List.Content floated="right" className="pull-right">
                <Link
                  to={"/admin/syntaxes/" + syntax._id + "/edit"}
                  className="inline"
                >
                  <Icon name="edit" color="violet" className="font-16" />
                </Link>
                <Icon
                  name="delete"
                  color="red"
                  onClick={() => this.handleClick(syntax._id)}
                  className="font-16"
                />
              </List.Content>
              <List.Content>
                <div class="content-heading">
                  <Link to={"/admin/syntax/" + syntax._id}>{syntax.title}</Link>

                  <div class="content-heading smallprint major-padding">
                    {syntax.property ? syntax.property.name : "No property"}
                  </div>
                </div>
                <div class="content-subheading">
                  {syntax.description}
                  <BoxContainer
                    propertyData={syntax}
                    language={syntax.language}
                  />
                </div>
              </List.Content>
            </List.Item>
            <div class="child-content">
              {syntax.child ? this.data(syntax.child) : ""}
            </div>
          </div>
        );
      });
    // );
    return data;
  };

  render() {
    return (
      <div>
        <Header />
        <div className="section_content">
          <div className="pull-left">
            <h3>
              <Icon name="code" />
              All saved code for {this.state.name}
            </h3>
          </div>
          <div className="pull-right">
            <h3>
              <Link
                to={
                  "/admin/syntaxes/new?language_id=" +
                  this.props.match.params.language_id
                }
              >
                <div className="">
                  <Icon name="add" className="add_icon" />
                </div>
              </Link>
            </h3>
          </div>
        </div>

        <br />
        <div
          className="section_content"
          style={{ margin: "40px 0px !important;" }}
        >
          <div className="">
            <a onClick={this.goBack} href="#" class="back-link">
              <Icon name="angle left" /> Go Back
            </a>
          </div>
        </div>

        <div className="section_content">
          <List divided verticalAlign="middle">
            {this.data(this.state.syntaxes)}
          </List>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(pShowSyntaxes);
