import React, { Component } from 'react';
import "./infobox.css"

class InfoBox extends Component {
    state = {  }
    render() { 
        return (
            <div className="infobox">{this.props.content}</div>
          );
    }
}
 
export default InfoBox;