import axios from "axios";

function create(
  language_id,
  property_id,
  version_id,
  title,
  wysiwyg,
  cheetsheet_links,
  stackoverflow_links,
  tutorial_links,
  docs_links,
  parent_properties,
  property_name,
  token
) {
  const auth = {
    headers: { Authorization: "Token " + token },
    "Content-Type": "application/json"
  };

  let body = {
    language_id: language_id,
    property_id: property_id,
    version_id: version_id,
    title: title,
    wysiwyg: wysiwyg,
    property_name: property_name,
    cheetsheet_links: cheetsheet_links,
    stackoverflow_links: stackoverflow_links,
    tutorial_links: tutorial_links,
    parent_properties: parent_properties,
    docs_links: docs_links
  };

  return axios
    .post(process.env.REACT_APP_BACKEND_URL + "/api/v1/syntaxes", body, auth)
    .then(result => {});
}

function index(username, token) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };
  return axios.get(
    process.env.REACT_APP_BACKEND_URL +
      "/api/v1/users/" +
      username +
      "/syntaxes",
    auth
  );
}

function show(username, language_id, token) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };
  return axios.get(
    process.env.REACT_APP_BACKEND_URL +
      "/api/v1/users/" +
      username +
      "/languageSyntaxes/" +
      language_id,
    auth
  );
}

function pshow(syntax_id, token, username) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };
  return axios.get(
    process.env.REACT_APP_BACKEND_URL +
      "/api/v1/users/" +
      username +
      "/psyntax/" +
      syntax_id,
    auth
  );
}

function find(id, token, username) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };
  return axios.get(
    process.env.REACT_APP_BACKEND_URL +
      "/api/v1/users/" +
      username +
      "/syntax/" +
      id,
    auth
  );
}

function update(
  language_id,
  property_id,
  version_id,
  title,
  wysiwyg,
  cheetsheet_links,
  stackoverflow_links,
  tutorial_links,
  docs_links,
  parent_properties,
  property_name,
  token,
  id
) {
  const auth = {
    headers: { Authorization: "Token " + token },
    "Content-Type": "application/json"
  };

  let body = {
    language_id: language_id,
    property_id: property_id,
    version_id: version_id,
    title: title,
    wysiwyg: wysiwyg,
    property_name: property_name,
    cheetsheet_links: cheetsheet_links,
    stackoverflow_links: stackoverflow_links,
    tutorial_links: tutorial_links,
    parent_properties: parent_properties,
    docs_links: docs_links
  };

  return axios.put(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/syntax/" + id,
    body,
    auth
  );
}

function destroy(id, token) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };

  return axios.delete(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/syntax/" + id,
    auth
  );
}

export const syntaxService = {
  create,
  index,
  find,
  show,
  update,
  destroy,
  pshow
};
