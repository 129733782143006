import React, { Component } from "react";
import Header from "../../components/header/header";
import Nav from "../../components/nav/nav";
import Footer from "../.././components/footer/footer";
import { mainService } from "../../services/main.service";
import { connect } from "react-redux";
import { categoryService } from "../../services";
import { Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import BoxContainer from "../boxContainer/boxContainer";
import InfoBox from "../../components/infoBox/infoBox";
import "./property.css";
import { Redirect } from "react-router-dom";

class User extends Component {
  state = {
    languages_data: [],
    data: [],
    properties: [],
    languages: [],
    defaultLanguages: [],
    tags: [],
    compare: [],
    activeItem: "",
    loggedIn: false,
    user: null,
    redirect_to_unauthorized: false
  };

  getProperty = () => {
    let property = this.state.data.filter(property => {
      return property._id === this.props.match.params.property_id;
    });
    return property[0] ? property[0].name : "Fetching.. wait...";
  };

  filter_data = event => {
    const value = event.target.value;
    if (value !== undefined || value !== "" || value !== null) {
      var properties = this.state.data.map(property => {
        if (property.name.toLowerCase().includes(event.target.value)) {
          return property;
        }
        return null;
      });
      properties = properties.filter(n => n);
      this.setState({
        properties: properties
      });
    } else {
      this.setState({
        properties: this.state.data
      });
    }
  };

  componentDidMount() {
    this.getTagsFromDB();
  }

  getTagsFromDB = () => {
    categoryService
      .compare(this.props.match.params.username, this.props.token)
      .then(res => {
        this.setState({
          compare: res.data
        });
      });

    categoryService
      .index(this.props.match.params.username, this.props.token)
      .then(res => {
        res.data.not
          ? this.setState({ redirect_to_unauthorized: true })
          : this.setState({
              tags: res.data
            });
        mainService
          .property(
            this.props.match.params.username,
            this.props.match.params.property_id,
            this.props.token
          )
          .then(res => {
            this.setState({
              data: res.data.properties,
              languages_data: res.data.languages,
              properties: res.data.properties[0],
              languages: res.data.languages.map(language => language.name),
              defaultLanguages: res.data.languages.map(
                language => language.name
              )
            });
          });
      });
  };

  languageChange = language => {
    let languages = [...this.state.defaultLanguages];
    if (this.state.languages.includes(language)) {
      languages = languages.filter(lang => this.state.languages.includes(lang));
      languages = languages.filter(lang => lang !== language);
      this.setState({ languages: languages });
    } else {
      languages = languages.filter(
        lang => this.state.languages.includes(lang) || lang === language
      );
      this.setState({ languages: languages });
    }
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const defaultLanguages = this.reorder(
      this.state.defaultLanguages,
      result.source.index,
      result.destination.index
    );
    this.setState({ defaultLanguages: defaultLanguages });
    if (this.state.languages.includes(result.draggableId)) {
      const languages = this.reorder(
        this.state.languages,
        result.source.index,
        result.destination.index
      );
      this.setState({ languages: languages });
    }
  };

  languageDragEnd = result => {
    if (!result.destination) {
      return;
    }
    let languages = ["javascript", "ruby", "python"];
    this.setState({ languages: languages });
  };

  filter_record = (language, propertyID) => {
    let langData = [];
    let syntax = [];
    langData = this.state.languages_data.filter(function(languageData) {
      return languageData.name === language;
    });
    syntax = langData[0].syntaxes.filter(function(syntax) {
      return syntax.property._id === propertyID;
    });
    return syntax;
  };

  render() {
    if (this.state.redirect_to_unauthorized) {
      return <Redirect to="/unauthorized/" />;
    }
    return (
      <div className="App">
        <Header
          _logout={this._logout}
          loggedIn={this.state.loggedIn}
          filter_data={this.filter_data}
        />
        <Nav
          tags={this.state.tags}
          user={this.props.match.params.username}
          compare={this.state.compare}
        />
        <InfoBox
          content={
            "Details of " +
            this.getProperty() +
            "  By " +
            this.props.match.params.username
          }
        />

        <div className="tableMatrix">
          <div className="table-header"> {this.state.properties.name}</div>
          <div className="properties-description">
            {this.state.properties.description}
          </div>
          <Table basic="very" celled collapsing className="isTable">
            <Table.Body>
              {this.state.languages_data.map(language => {
                return (
                  <Table.Row key={language._id}>
                    <Table.Cell className="row-property">
                      <Link
                        to={
                          "/users/" +
                          this.props.match.params.username +
                          "/languages/" +
                          language._id
                        }
                      >
                        {language.name}
                      </Link>
                    </Table.Cell>
                    <Table.Cell key={language.name} className="row-container">
                      <BoxContainer
                        propertyData={this.filter_record(
                          language.name,
                          this.props.match.params.property_id
                        )}
                        language={language.name}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(User);
