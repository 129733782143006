import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import BoxContainer from "../boxContainer/boxContainer";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class TableRow extends Component {
  state = {};
  filter_record = (language, propertyID) => {
    let langData = [];
    let syntax = [];
    langData = this.props.data.filter(function(languageData) {
      return languageData.name === language;
    });
    syntax = langData[0].syntaxes.filter(function(syntax) {
      return syntax.property._id === propertyID;
    });
    return syntax;
  };
  render() {
    return (
      <Table.Row>
        <Table.Cell className="row-property">
          <Link
            to={
              "/users/" +
              this.props.user +
              "/properties/" +
              this.props.property._id
            }
          >
            {this.props.property.name.charAt(0).toUpperCase() +
              this.props.property.name.slice(1)}
          </Link>
        </Table.Cell>
        {this.props.languages.map(language => {
          return (
            <Table.Cell key={language} className="row-container">
              <BoxContainer
                propertyData={this.filter_record(
                  language,
                  this.props.property._id
                )}
                language={language}
              />
            </Table.Cell>
          );
        })}
      </Table.Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(TableRow);
