import React, { Component } from "react";
import Header from "../../components/header/header";
import Nav from "../../components/nav/nav";
import TableMatrix from "../.././components/table/tableMatrix";
import Footer from "../.././components/footer/footer";
import { mainService } from "../../services/main.service";
import { connect } from "react-redux";
import { categoryService } from "../../services";

class User extends Component {
  state = {
    languages_data: [],
    data: [],
    properties: [],
    languages: [],
    defaultLanguages: [],
    tags: [],
    compare: [],
    activeItem: "",
    loggedIn: false,
    user: null
  };

  filter_data = event => {
    const value = event.target.value;
    if (value !== undefined || value !== "" || value !== null) {
      var properties = this.state.data.map(property => {
        if (property.name.toLowerCase().includes(event.target.value)) {
          return property;
        }
        return null;
      });
      properties = properties.filter(n => n);
      this.setState({
        properties: properties
      });
    } else {
      this.setState({
        properties: this.state.data
      });
    }
  };

  componentDidMount() {
    this.getTagsFromDB();
  }

  getTagsFromDB = () => {
    categoryService
      .compare(this.props.match.params.username, this.props.token)
      .then(res => {
        this.setState({
          compare: res.data
        });
      });

    categoryService
      .index(this.props.match.params.username, this.props.token)
      .then(res => {
        this.setState({
          tags: res.data
        });
        mainService
          .main(this.props.match.params.username, res.data[0]._id)
          .then(res => {
            this.setState({
              data: res.data.properties,
              languages_data: res.data.languages,
              properties: res.data.properties,
              languages: res.data.languages.map(language => language.name),
              defaultLanguages: res.data.languages.map(
                language => language.name
              )
            });
          });
      });
  };

  languageChange = language => {
    let languages = [...this.state.defaultLanguages];
    if (this.state.languages.includes(language)) {
      languages = languages.filter(lang => this.state.languages.includes(lang));
      languages = languages.filter(lang => lang !== language);
      this.setState({ languages: languages });
    } else {
      languages = languages.filter(
        lang => this.state.languages.includes(lang) || lang === language
      );
      this.setState({ languages: languages });
    }
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const defaultLanguages = this.reorder(
      this.state.defaultLanguages,
      result.source.index,
      result.destination.index
    );
    this.setState({ defaultLanguages: defaultLanguages });

    if (this.state.languages.includes(result.draggableId)) {
      const languages = this.reorder(
        this.state.languages,
        result.source.index,
        result.destination.index
      );
      this.setState({ languages: languages });
    }
  };

  languageDragEnd = result => {
    if (!result.destination) {
      return;
    }
    let languages = ["javascript", "ruby", "python"];
    this.setState({ languages: languages });
  };

  render() {
    return (
      <div className="App">
        <Header
          _logout={this._logout}
          loggedIn={this.state.loggedIn}
          filter_data={this.filter_data}
        />
        <Nav
          tags={this.state.tags}
          user={this.props.match.params.username}
          compare={this.state.compare}
        />
        <TableMatrix
          languages={this.state.languages}
          defaultLanguages={this.state.defaultLanguages}
          properties={this.state.properties}
          data={this.state.languages_data}
          languageChange={this.languageChange}
          onDragEnd={this.onDragEnd}
        />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(User);
