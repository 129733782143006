import React, { Component } from "react";
import "./links.css";

class Links extends Component {
  render() {
    return (
      <h1>
        <div className="links">
          <div className="link_head">
            {this.props.linksName !== undefined &&
            (this.props.links !== undefined && this.props.links.length !== 0)
              ? this.props.linksName
                  .split("_")
                  .map(prop => prop.charAt(0).toUpperCase() + prop.slice(1))
                  .join(" ")
              : ""}
          </div>
          {this.props.links !== undefined
            ? this.props.links.map(link => {
                return (
                  <a className="link" href={link} target="_" key={link}>
                    {link}
                  </a>
                );
              })
            : ""}
        </div>
      </h1>
    );
  }
}

export default Links;
