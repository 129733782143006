import React, { Component } from "react";
import Header from "../../header/header";
import { Button, Form, Input, TextArea, Icon } from "semantic-ui-react";
import { categoryService } from "../../../services/category.service";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Footer from "../../../components/footer/footer";
import { Header as SemanticHeader } from "semantic-ui-react";

class newCategory extends Component {
  state = {
    name: "",
    description: "",
    redirect: false
  };

  handleSubmit = e => {
    e.preventDefault();
    const name = this.state.name;
    const description = this.state.description;
    const token = this.props.token;
    categoryService.create(name, description, token).then(response => {
      return this.setState({ redirect: true });
    });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  goBack = e => {
    this.props.history.goBack();
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/admin/categories/" />;
    }

    return (
      <div>
        <Header />
        <div className="section_content">
          <div className="">
            <a onClick={this.goBack} href="#" class="back-link">
              <Icon name="angle left" /> Go Back
            </a>
          </div>
        </div>
        <Form className="form" onSubmit={this.handleSubmit}>
          <SemanticHeader as="h4" content="New Type" textAlign="left" />

          <Form.Group widths="equal">
            <Form.Field
              id="form-input-control-error-name"
              control={Input}
              label="Name"
              placeholder="Name"
              value={this.state.name}
              name="name"
              onChange={this.handleChange}
              required
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              id="form-textarea-control-description"
              control={TextArea}
              label="Description"
              placeholder="Description"
              value={this.state.description}
              name="description"
              onChange={this.handleChange}
              required
            />
          </Form.Group>

          <Form.Field
            id="form-button-control-public"
            control={Button}
            content="Submit"
            color="violet"
            position="right"
            textAlign="right"
            float="right"
            right
            label=""
          />
        </Form>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(newCategory);
