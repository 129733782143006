import React, { Component } from "react";
import Header from "../../header/header";
import Sidebar from "../../sidebar/sidebar";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import { categoryService } from "../../../services/category.service";
import { Link } from "react-router-dom";
import "../admin.css";
import { Grid, Segment } from "semantic-ui-react";
import Footer from "../../../components/footer/footer";

import { Button, List } from "semantic-ui-react";

class Compare extends Component {
  state = {
    categories: []
  };

  //component did mount
  componentDidMount() {
    categoryService
      .compare(this.props.username, this.props.token)
      .then(response => {
        console.log("response", response);
        let newCategories = response.data;
        this.setState({ categories: newCategories });
      });
  }

  //local methods
  handleClick = id => {
    const token = this.props.token;
    categoryService.destroy(id, token).then(response => {
      let newCategories = this.state.categories.filter(category => {
        return category._id !== id;
      });
      this.setState({
        categories: newCategories
      });
    });
  };

  getCompares = category => {
    if (category.languages.map(lang => lang.name).join(" ") != "") {
      return "Compares " + category.languages.map(lang => lang.name).join(" ");
    } else {
      return "Please add cheatsheets with similar properties for compare";
    }
  };

  //data
  data = () => {
    let data =
      this.state.categories.length === 0 ? (
        <h3 className="no-categories">
          Add a new comparison object to get started
        </h3>
      ) : (
        this.state.categories.map(category => {
          return (
            <List.Item key={category._id}>
              <List.Content floated="right">
                <Link
                  to={"/admin/compare/" + category._id + "/edit"}
                  className="inline"
                >
                  {/* <Button className="inline" color="teal"> */}
                  <Icon name="edit" color="violet" className="font-16" />

                  {/* </Button> */}
                </Link>
                {/* <Button
                  onClick={() => this.handleClick(category._id)}
                  className="inline"
                  color="red"
                > */}
                <Icon
                  name="delete"
                  color="red"
                  onClick={() => this.handleClick(category._id)}
                  className="font-16"
                />

                {/* </Button> */}
              </List.Content>
              <List.Content>
                {/* <Link
                  to={
                    "/users/" +
                    this.props.username +
                    "/categories/" +
                    category._id
                  }
                > */}
                <div class="content-heading">
                  {category.name}
                  <div class="content-heading smallprint">
                    {this.getCompares(category)}
                  </div>
                </div>
                <div class="content-subheading">{category.description}</div>
                {/* </Link> */}
              </List.Content>
            </List.Item>
          );
        })
      );
    return data;
  };

  render() {
    return (
      <div>
        <Header />
        <div className="section_content">
          <div className="pull-left">
            <h3>
              <Icon name="random" />
              Compare properties across similar cheetsheets or types
            </h3>
          </div>
          <div className="pull-right">
            <h3>
              <Link to="/admin/compare/new">
                <div className="">
                  <Icon name="add" className="add_icon" />
                </div>
              </Link>
            </h3>
          </div>
        </div>

        <div className="section_content index_flex">
          <List divided verticalAlign="middle">
            {this.data()}
          </List>
        </div>
        <Footer />
      </div>
    );
  }
}

//redux
const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(Compare);
