import React, { Component } from "react";
import { Grid, Dropdown, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import "./nav.css";

class Nav extends Component {
  findActive(id, tag) {
    let active = id === tag ? " nav-active" : "";
    return "nav-link" + active;
  }

  render() {
    return (
      <div className="nav">
        <Grid className="nav-grid" columns={5} divided>
          <Grid.Row>
            <Grid.Column key="cheatsheets" className="nav_ul">
              <Dropdown
                text="Cheatsheets"
                className="link item simple nav-dropdown"
              >
                <Menu className="nav-dropdown-menu" secondary vertical>
                  {this.props.tags.map((tag, index) => {
                    return (
                      <Dropdown item text={tag.name}>
                        <Dropdown.Menu>
                          <Dropdown.Header className="nav-dropdown-header">
                            {"{CS}"}
                          </Dropdown.Header>
                          {tag.languages
                            ? tag.languages.map((language, index) => {
                                return (
                                  <Link
                                    to={
                                      "/users/" +
                                      this.props.user +
                                      "/languages/" +
                                      language._id
                                    }
                                    className="link nav-dropdown-link"
                                    key={language._id}
                                  >
                                    <Dropdown.Item>
                                      {language.name}
                                    </Dropdown.Item>
                                  </Link>
                                );
                              })
                            : ""}
                        </Dropdown.Menu>
                      </Dropdown>
                    );
                  })}
                </Menu>
              </Dropdown>
            </Grid.Column>
            <Grid.Column key="cheatsheets" className="nav_ul">
              <Dropdown
                text="Compare(new)"
                className="link item simple nav-dropdown"
              >
                <Dropdown.Menu>
                  {this.props.compare.map((com, index) => {
                    return (
                      <Link
                        to={
                          "/users/" + this.props.user + "/categories/" + com._id
                        }
                        className={this.findActive(this.props.id, com._id)}
                      >
                        <Dropdown.Item text={com.name}></Dropdown.Item>
                      </Link>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>

            <Grid.Column key="cheatsheets" className="nav_ul">
              <Dropdown
                text="Projects(new)"
                className="link item simple nav-dropdown"
              >
                <Dropdown.Menu className="nav-dropdown-menu">
                  <Dropdown.Header className="nav-dropdown-header"></Dropdown.Header>
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
            {/* {this.props.tags.map((tag, index) => {
              return (
                <Grid.Column key={index + " " + tag} className="nav_ul">
                  <Link
                    to={"/users/" + this.props.user + "/categories/" + tag._id}
                    className={this.findActive(this.props.id, tag._id)}
                  >
                    <Dropdown
                      text={tag.name}
                      className="link item simple nav-dropdown"
                    >
                      <Dropdown.Menu className="nav-dropdown-menu">
                        <Dropdown.Header className="nav-dropdown-header">
                          Languages
                        </Dropdown.Header>
                        {tag.languages
                          ? tag.languages.map((language, index) => {
                              return (
                                <Link
                                  to={
                                    "/users/" +
                                    this.props.user +
                                    "/languages/" +
                                    language._id
                                  }
                                  className="link nav-dropdown-link"
                                  key={language._id}
                                >
                                  <Dropdown.Item>{language.name}</Dropdown.Item>
                                </Link>
                              );
                            })
                          : ""}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Link>
                </Grid.Column>
              );
            })} */}
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(Nav);
