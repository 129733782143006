import React, { Component } from "react";
import Header from "../../header/header";
import Sidebar from "../../sidebar/sidebar";
import { connect } from "react-redux";
import { syntaxService } from "../../../services/syntax.service";
import { Link } from "react-router-dom";
import { Grid, Segment, Button, List, Icon } from "semantic-ui-react";
import Footer from "../../footer/footer";
// import Footer from "../../../components/footer/footer";

class showSyntaxes extends Component {
  state = {
    syntaxes: []
  };

  handleClick = id => {
    const token = this.props.token;
    syntaxService.destroy(id, token).then(response => {
      let newSyntaxes = this.state.syntaxes.filter(syntax => {
        return syntax._id !== id;
      });
      this.setState({
        syntaxes: newSyntaxes
      });
    });
  };

  componentDidMount() {
    syntaxService
      .show(
        this.props.username,
        this.props.match.params.language_id,
        this.props.token
      )
      .then(response => {
        console.log("res2", response);
        let newSyntaxes = response.data;
        this.setState({ syntaxes: newSyntaxes });
      });
  }

  data = () => {
    let data =
      this.state.syntaxes.length === 0 ? (
        <div>
          <h3 className="no-categories">No posts for this language</h3>
          <Link
            to={
              "/admin/syntaxes/new?language_id=" +
              this.props.match.params.language_id
            }
          >
            <div className="margin-high">
              <Icon name="add" className="huegreen" />
            </div>
          </Link>
          <div className="clearfix" />
        </div>
      ) : (
        <div>
          <Link
            to={
              "/admin/syntaxes/new?language_id=" +
              this.props.match.params.language_id
            }
          >
            <div className="margin-high">
              <Icon name="add" className="huegreen" />
            </div>
          </Link>
          <div className="clearfix" />

          {this.state.syntaxes.map(syntax => {
            let datw =
              syntax.language._id === this.props.match.params.language_id ? (
                <List divided verticalAlign="middle" key={"synt" + syntax._id}>
                  <List.Item key={syntax._id}>
                    <List.Content floated="right">
                      <Link
                        to={"/admin/syntaxes/" + syntax._id + "/edit"}
                        className="inline"
                      >
                        <Button className="inline" color="teal">
                          <Icon name="edit" />
                          Edit
                        </Button>
                      </Link>
                      <Button
                        onClick={() => this.handleClick(syntax._id)}
                        className="inline"
                        color="red"
                      >
                        <Icon name="delete" />
                        Delete
                      </Button>
                    </List.Content>
                    <List.Content>
                      {syntax.property.name + " - " + syntax.title}
                    </List.Content>
                  </List.Item>
                </List>
              ) : (
                ""
              );

            return datw;
          })}
        </div>
      );
    return data;
  };

  render() {
    return (
      <div className="syntaxes">
        <Header />
        <Grid id="uigrid">
          <Sidebar item="syntaxes" />
          <Grid.Column stretched width={12}>
            <Segment>
              <div className="section create">
                {this.props.match.params.language_id === undefined
                  ? ""
                  : this.data()}
              </div>
            </Segment>
          </Grid.Column>
        </Grid>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(showSyntaxes);
