import React, { Component } from "react";
import Header from "../../header/header";
import Sidebar from "../../sidebar/sidebar";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import { languageService } from "../../../services/language.service";
import { Link } from "react-router-dom";
import { Grid, Segment } from "semantic-ui-react";
import Footer from "../../../components/footer/footer";
import { Button, List } from "semantic-ui-react";
import { categoryService } from "../../../services/category.service";

class Languages extends Component {
  state = {
    languages: [],
    categories: 0,
    private: false
  };

  handleClick = id => {
    const token = this.props.token;
    languageService.destroy(id, token).then(response => {
      let newLanguages = this.state.languages.filter(language => {
        return language._id !== id;
      });
      this.setState({
        languages: newLanguages
      });
    });
  };

  handlePermission = (id, privat) => {
    let privy = privat === undefined || privat !== true ? "true" : "false";

    languageService
      .language_private(id, this.props.token, this.props.username, privy)
      .then(res => {
        languageService
          .index(this.props.username, this.props.token)
          .then(response => {
            let newLanguages = response.data;
            this.setState({ languages: newLanguages });
          })
          .then(
            categoryService
              .index(this.props.username, this.props.token)
              .then(response => {
                this.setState({ categories: response.data.length });
              })
          );
      });
  };

  componentDidMount() {
    languageService
      .index(this.props.username, this.props.token)
      .then(response => {
        let newLanguages = response.data;
        this.setState({ languages: newLanguages });
      })
      .then(
        categoryService
          .index(this.props.username, this.props.token)
          .then(response => {
            this.setState({ categories: response.data.length });
          })
      );
  }
  data = () => {
    let data =
      this.state.languages.length === 0 ? (
        <h3 className="no-categories">No languages added</h3>
      ) : (
        this.state.languages.map(language => {
          return (
            <List.Item key={language._id}>
              <List.Content floated="right">
                <Link
                  to={"/admin/languages/" + language._id + "/edit"}
                  className="inline"
                >
                  <Icon name="edit" color="violet" className="font-16" />
                </Link>
                <Icon
                  name="delete"
                  color="red"
                  onClick={() => this.handleClick(language._id)}
                  className="font-16"
                />
              </List.Content>
              <List.Content>
                {/* <Link
                  to={
                    "/users/" +
                    this.props.username +
                    "/categories/" +
                    category._id
                  }
                > */}
                <div class="content-heading">
                  <Link to={"/admin/cheatsheets/" + language._id}>
                    {language.name}
                  </Link>

                  <div class="content-heading smallprint major-padding">
                    <span
                      onClick={() =>
                        this.handlePermission(language._id, language.private)
                      }
                      className="inline privacy"
                    >
                      <Icon name="privacy" />
                      {language.private ? "Make Public" : "Make Private"}
                    </span>
                    <div>
                      {language.private
                        ? "Temporary Password:  " +
                          language.temporaryPassword +
                          "      "
                        : ""}
                    </div>
                  </div>
                </div>
                <div class="content-subheading">{language.description}</div>
                {/* </Link> */}
              </List.Content>
            </List.Item>
          );
        })
      );
    return data;
  };

  render() {
    return (
      <div>
        <Header />
        <div className="section_content">
          <div className="pull-left">
            <h3>
              <Icon name="tree" />
              Cheatsheets
            </h3>
          </div>
          <div className="pull-right">
            <h3>
              <Link to="/admin/languages/new">
                <div className="">
                  <Icon name="add" className="add_icon" />
                </div>
              </Link>
            </h3>
          </div>
        </div>

        <div className="section_content index_flex">
          <List divided verticalAlign="middle">
            {this.data()}
          </List>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(Languages);
