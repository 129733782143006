import React, { Component } from "react";
import { userService } from "../../../services";
import { connect } from "react-redux";
import { addEmailTokenToStore } from "../../../actions/loginActions";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment
} from "semantic-ui-react";
import { default as Head } from "../../../components/header/header";

import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Footer from "../../../components/footer/footer";
import queryString from "query-string";
import { Icon } from "semantic-ui-react";
import "./login.css";

class LoginForm extends Component {
  state = {
    email: "",
    password: "",
    redirect: false
  };

  componentDidMount() {
    var query = queryString.parse(this.props.location.search);
    if (query.token) {
      this.props.addEmailTokenToStore(
        query.token,
        query.email,
        query.username,
        new Date()
      );
      localStorage.setItem("email", query.email);
      localStorage.setItem("token", query.token);
      localStorage.setItem("username", query.username);
      localStorage.setItem("timestamp", new Date());
      this.setState({ redirect: true });
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const email = this.state.email;
    const password = this.state.password;
    userService.login(email, password).then(response => {
      this.props.addEmailTokenToStore(
        response.data.user.token,
        response.data.user.email,
        response.data.user.username,
        new Date()
      );
      localStorage.setItem("email", response.data.user.email);
      localStorage.setItem("token", response.data.user.token);
      localStorage.setItem("username", response.data.user.username);
      localStorage.setItem("timestamp", new Date());
      return this.setState({ redirect: true });
    });
  };

  handleGoogle() {
    userService.google();
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/admin/categories/" />;
    }
    return (
      <div className="login-form">
        <style>
          {`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}
        </style>
        <Head />
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450, marginTop: -100 }}>
            <Header
              as="h2"
              color="orange"
              className="login-header"
              textAlign="center"
            >
              {/*<Image src="/logo.png" />*/} Log-in to your account
            </Header>
            <Form size="large" onSubmit={this.handleSubmit}>
              <Segment stacked>
                <Form.Input
                  icon="user"
                  iconPosition="left"
                  label="Email"
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <Form.Input
                  icon="lock"
                  iconPosition="left"
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
                <Button color="orange inverted" fluid size="large">
                  Login
                </Button>
                <div className="google">
                  <a href="/auth/google" className="button google-button">
                    <Icon
                      name="google"
                      className="button-label vertical-super google-icon"
                    />
                    <span className="button-label vertical-super">
                      Sign in with Google
                    </span>
                  </a>
                </div>
              </Segment>
            </Form>
            <Message>
              <Link to="/signup" className="loginp-link">
                Sign Up
              </Link>
              <Link to="/forgotpassword" className="loginp-link">
                Forgot Password?
              </Link>
            </Message>
          </Grid.Column>
        </Grid>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addEmailTokenToStore: (token, email, username, timestamp) =>
      dispatch(addEmailTokenToStore(token, email, username, timestamp))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);
