import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment
} from "semantic-ui-react";
import { default as Head } from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import "../login/login.css";
import { userService } from "../../../services/user.service";
import "../login/login.css";

class SignupForm extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      redirectTo: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    // TODO - validate!
    userService.forgot_password(this.state.email).then(response => {
      if (!response.data.errmsg) {
        this.setState({
          redirectTo: "/login"
        });
      } else {
        console.log("Already exists");
      }
    });
  }
  render() {
    if (this.state.redirectTo) {
      return <Redirect to={{ pathname: this.state.redirectTo }} />;
    }
    return (
      <div className="login-form">
        <style>
          {`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}
        </style>
        <Head />
        <Grid
          className="row"
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450, marginTop: -100 }}>
            <Header
              as="h2"
              color="orange"
              className="login-header"
              textAlign="center"
            >
              Forgot Password
            </Header>
            <Form size="large" onSubmit={this.handleSubmit}>
              <Segment stacked>
                <Form.Input
                  icon="user"
                  iconPosition="left"
                  label="Email"
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />

                <Button color="orange inverted" fluid size="large">
                  Forgot Password
                </Button>
              </Segment>
            </Form>
            <Message>
              Already a member?
              <Link to="/login" className="loginp-link">
                Login
              </Link>
            </Message>
          </Grid.Column>
        </Grid>
        <Footer />
      </div>
    );
  }
}

export default SignupForm;
