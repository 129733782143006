import axios from "axios";

function main(username, category_id, token) {
  const token_val = token ? "Token " + token : null;
  const auth = {
    headers: { Authorization: token_val }
  };
  return axios.get(
    process.env.REACT_APP_BACKEND_URL +
      "/api/v1/users/" +
      username +
      "/" +
      category_id,
    auth
  );
}

function language(username, language_id, token) {
  const token_val = token ? "Token " + token : null;
  const auth = {
    headers: { Authorization: token_val }
  };
  return axios.get(
    process.env.REACT_APP_BACKEND_URL +
      "/api/v1/users/" +
      username +
      "/showlanguage/" +
      language_id,
    auth
  );
}

function property(username, property_id, token) {
  const token_val = token ? "Token " + token : null;
  const auth = {
    headers: { Authorization: token_val }
  };
  return axios.get(
    process.env.REACT_APP_BACKEND_URL +
      "/api/v1/users/" +
      username +
      "/showproperty/" +
      property_id,
    auth
  );
}

function languages(user) {
  user = user ? user : "not";
  return axios.get(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/main/languages?user=" + user
  );
}

function users(user) {
  user = user ? user : "not";
  return axios.get(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/main/users?user=" + user
  );
}

function categories(user) {
  user = user ? user : "not";
  return axios.get(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/main/categories?user=" + user
  );
}

export const mainService = {
  main,
  language,
  property,
  languages,
  categories,
  users
};
