export const addEmailTokenToStore = (token, email, username, timestamp) => {
  return {
    type: "ADD_TOKEN_EMAIL",
    payload: token,
    email: email,
    username: username,
    timestamp: timestamp
  };
};

export const removeEmailTokenFromStore = (
  token,
  email,
  username,
  timestamp
) => {
  return {
    type: "REMOVE_TOKEN_EMAIL",
    payload: token,
    email: email,
    username: username,
    timestamp: timestamp
  };
};

export const addLanguageTempPasswordToStore = (
  language_id,
  temporary_password
) => {
  return {
    type: "Add_Language_Temp_Password_To_Store",
    payload: language_id,
    temporary_password: temporary_password
  };
};
