import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import TableHead from "../tableHead/tableHead";
import TableRow from "../tableRow/tableRow";
import LanguageTags from "../languageTags/languageTags";
import "./table-matrix.css";

class TableMatrix extends Component {
  constructor(props) {
    super(props);
    this.languageChange = this.props.languageChange.bind(this);
    this.onDragEnd = this.props.onDragEnd.bind(this);
  }

  componentDidMount() {}
  state = {};
  render() {
    return (
      <div className="tableMatrix" attached="bottom">
        {this.props.ert === "value" ? "" : ""}

        <Table basic="very" celled collapsing className="isTable">
          <TableHead languages={this.props.languages} />
          <Table.Body>
            {this.props.properties.map((property, index) => {
              return (
                <TableRow
                  property={property}
                  languages={this.props.languages}
                  data={this.props.data}
                  user={this.props.user}
                  key={index + " " + property._id}
                />
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default TableMatrix;
