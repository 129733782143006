import React, { Component } from "react";
import Header from "../../header/header";
import Sidebar from "../../sidebar/sidebar";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import { propertyService } from "../../../services/property.service";
import { Link } from "react-router-dom";
import { Button, List } from "semantic-ui-react";
import { Grid, Segment } from "semantic-ui-react";
import Footer from "../../../components/footer/footer";
import { categoryService } from "../../../services/category.service";

class Properties extends Component {
  state = {
    properties: [],
    categories: ""
  };

  handleClick = id => {
    const token = this.props.token;
    propertyService.destroy(id, token).then(response => {
      let newProperties = this.state.properties.filter(property => {
        return property._id !== id;
      });
      this.setState({
        properties: newProperties
      });
    });
  };

  componentDidMount() {
    propertyService
      .index(this.props.username, this.props.token)
      .then(response => {
        let newProperties = response.data;
        this.setState({ properties: newProperties });
      })
      .then(
        categoryService
          .index(this.props.username, this.props.token)
          .then(response => {
            this.setState({ categories: response.data.length });
          })
      );
  }

  data = () => {
    let data =
      this.state.properties.length === 0 ? (
        <div>
          <h3 className="no-categories">No features added</h3>
        </div>
      ) : (
        this.state.properties.map(property => {
          return (
            <List.Item key={property._id}>
              <List.Content floated="right">
                <Link
                  to={"/admin/properties/" + property._id + "/edit"}
                  className="inline"
                >
                  <Button className="inline" color="teal">
                    <Icon name="edit" />
                    Edit
                  </Button>
                </Link>
                <Button
                  onClick={() => this.handleClick(property._id)}
                  className="inline"
                  color="red"
                >
                  <Icon name="delete" />
                  Delete
                </Button>
              </List.Content>
              <List.Content>
                <Link
                  to={
                    "/users/" +
                    this.props.username +
                    "/properties/" +
                    property._id
                  }
                >
                  {property.name}
                </Link>
              </List.Content>
            </List.Item>
          );
        })
      );
    return data;
  };

  render() {
    return (
      <div className="properties">
        <Header />
        <Grid id="uigrid">
          <Sidebar item="properties" />
          <Grid.Column stretched width={12}>
            <Segment>
              <div className="section create">
                <Link to="/admin/properties/new">
                  <div className="margin-high">
                    <Icon name="add" className="huegreen" />
                  </div>
                </Link>
                <div className="clearfix" />
                <List divided verticalAlign="middle">
                  {this.data()}
                </List>
              </div>
            </Segment>
          </Grid.Column>
        </Grid>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(Properties);
