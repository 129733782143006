import React, { Component } from "react";
import Header from "../../header/header";
import Sidebar from "../../sidebar/sidebar";
import { Button, Form } from "semantic-ui-react";
import { propertyService } from "../../../services/property.service";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Grid, Segment } from "semantic-ui-react";
import Footer from "../../../components/footer/footer";

class editProperty extends Component {
  state = {
    name: "",
    description: "",
    links: [],
    redirect: false
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    propertyService
      .find(id, this.props.token, this.props.username)
      .then(response => {
        if (response.data !== []) {
          this.setState({
            name: response.data[0].name,
            description: response.data[0].description,
            links: response.data[0].links ? response.data[0].links : []
          });
        }
      });
  }

  handleSubmit = e => {
    e.preventDefault();
    propertyService
      .update(
        this.state.name,
        this.state.description,
        this.state.links,
        this.props.token,
        this.props.match.params.id
      )
      .then(response => {
        return this.setState({ redirect: true });
      });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleArrayChange = (element, event) => {
    let newArray = [...this.state[element]];
    let id = event.target.id;
    newArray[id.replace("element_", "")] = event.target.value;
    newArray = newArray.filter(function(element) {
      return element !== undefined;
    });
    let newHash = {};
    newHash[element] = newArray;
    this.setState(newHash);
  };

  handleAddElement = element => {
    let newHash = {};
    newHash[element] = [...this.state[element], ""];
    this.setState(newHash);
  };

  handleRemoveElement = (element, id) => {
    let newArray = this.state[element].map((el, index) => {
      if (id === "element_" + index) {
        return undefined;
      }
      return el;
    });
    newArray = newArray.filter(function(el) {
      return el !== undefined;
    });
    let newHash = {};
    newHash[element] = newArray;
    this.setState(newHash);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/admin/properties/" />;
    }

    return (
      <div className="properties">
        <Header />
        <Grid id="uigrid">
          <Sidebar item="properties" />
          <Grid.Column stretched width={12}>
            <Segment>
              <div className="content section">
                <Form onSubmit={this.handleSubmit}>
                  <Form.Field className="form-field">
                    <label className="form-label">Name</label>
                    <Form.Input
                      placeholder="Name"
                      value={this.state.name}
                      name="name"
                      onChange={this.handleChange}
                      required
                    />
                  </Form.Field>
                  <Form.Field className="form-field">
                    <label className="form-label">Description</label>
                    <Form.TextArea
                      placeholder="Description"
                      value={this.state.description}
                      name="description"
                      onChange={this.handleChange}
                      required
                    />
                  </Form.Field>
                  {/*<Form.Field className="form-field">
                    <label className="form-label">Links</label>
                    <ArrayForm
                      array={this.state.links}
                      onStateChange={this.sync}
                      handleRemoveElement={opts =>
                        this.handleRemoveElement("links", opts)
                      }
                      handleArrayChange={opts =>
                        this.handleArrayChange("links", opts)
                      }
                      handleAddElement={opts => this.handleAddElement("links")}
                    />
                    </Form.Field>*/}
                  <Button type="submit">Submit</Button>
                </Form>
              </div>
            </Segment>
          </Grid.Column>
        </Grid>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(editProperty);
