import React, { Component } from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { userService } from "../../services/user.service";

export class UserDropDown extends Component {
  state = {
    redirect: false,
    private: false,
    username: "",
    email: "",
    full_name: "",
    name: ""
  };

  componentDidMount() {
    userService.show(this.props.token, this.props.username).then(res => {
      this.setState({
        private: res.data.user.private,
        username: res.data.user.username,
        email: res.data.user.local.email,
        full_name: res.data.full_name
      });
    });
  }

  handleLogout = () => {
    const token = this.props.token;
    userService.logout(token).then(response => {
      console.log("Logged out");
    });
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    localStorage.removeItem("timestamp");
    localStorage.removeItem("username");
    this.setState({ logged: false });
    window.location.reload();
  };

  render() {
    const trigger = (
      <span>
        <Icon name="user" /> Hello, {this.state.full_name}
      </span>
    );

    const options = [
      {
        key: "user",
        text: (
          <span>
            Signed in as <strong>{this.state.full_name}</strong>
          </span>
        ),
        disabled: true
      },
      {
        key: "languages",
        text: "Cheatsheet types",
        as: Link,
        icon: "tree",
        to: "/admin/categories"
      },
      {
        key: "languages",
        text: "Your Cheatsheets",
        as: Link,
        icon: "code",
        to: "/admin/cheatsheets"
      },
      {
        key: "languages",
        text: "Compare(new)",
        as: Link,
        icon: "random",
        to: "/admin/compare"
      },
      {
        key: "languages",
        text: "Projects(new)",
        as: Link,
        icon: "warehouse",
        to: "/admin/cheatsheets"
      },
      {
        key: "profile",
        text: "Your Profile",
        icon: "user",
        as: Link,
        to: "/admin/settings"
      },
      {
        key: "sign-out",
        text: "Sign Out",
        icon: "sign out",
        onClick: () => this.handleLogout()
      }
    ];
    return (
      <div class="headerUserDropDown">
        <Dropdown trigger={trigger} options={options} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(UserDropDown);
