import React, { Component } from "react";
import Header from "../../header/header";
import Sidebar from "../../sidebar/sidebar";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, Segment } from "semantic-ui-react";
import Footer from "../../../components/footer/footer";
import { languageService } from "../../../services/language.service";
import { propertyService } from "../../../services/property.service";

class Syntaxes extends Component {
  state = {
    languages: [],
    properties: 0
  };

  componentDidMount() {
    languageService
      .index(this.props.username, this.props.token)
      .then(response => {
        let newLanguages = response.data;
        this.setState({ languages: newLanguages });
      })
      .then(
        propertyService
          .index(this.props.username, this.props.token)
          .then(response => {
            this.setState({ properties: response.data.length });
          })
      );
  }

  page = () => {
    return parseInt(this.state.languages.length, 10) < 1 ||
      this.state.languages.length === undefined ? (
      <div>
        <h3 className="no-categories">
          <Link to={"/admin/languages/new"}>
            Please add languages/tools to continue
          </Link>
        </h3>
      </div>
    ) : this.state.properties === 0 ? (
      <h3 className="no-categories">
        <Link to={"/admin/properties/new"}>
          Please add features/attributes/methods to continue
        </Link>
      </h3>
    ) : (
      <Grid>
        {this.state.languages.map(language => (
          <Link
            to={"/admin/syntaxes/" + language._id}
            key={"lang" + language._id}
          >
            <Grid.Column className="samaj" largeScreen={2} widescreen={1}>
              {language.name}
            </Grid.Column>
          </Link>
        ))}
      </Grid>
    );
  };

  render() {
    return (
      <div className="syntaxes">
        <Header />
        <Grid id="uigrid">
          <Sidebar item="syntaxes" />
          <Grid.Column stretched width={12}>
            <Segment>
              <div className="section create">{this.page()}</div>
            </Segment>
          </Grid.Column>
        </Grid>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(Syntaxes);
