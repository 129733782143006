import {
  Button,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Grid
} from "semantic-ui-react";
import React, { Component } from "react";
import InfoBox from "../../components/infoBox/infoBox";
import LanguageTags from "../languageTags/languageTags";
import "./horti.css";

class HorizontalSidebar extends Component {
  state = {
    animation: "scale down",
    direction: "bottom",
    visible: false
  };

  handleClick = () => {
    let newState = this.state.visible === true ? false : true;
    this.setState({ visible: newState });
  };

  handleAnimationChange = animation => () =>
    this.setState(prevState => ({ animation, visible: !prevState.visible }));
  render() {
    const { animation, dimmed, direction, visible } = this.state;

    return (
      <div>
        {/* <Button
          className="horizontal-button"
          onClick={() => this.handleClick()}
        >
          Change Order
        </Button> */}

        <Sidebar
          animation={"scale down"}
          direction={"right"}
          visible={this.state.visible}
        >
          <Grid textAlign="center" className="margin-top">
            <Grid.Row columns={1}>
              <Grid.Column>
                <h3>Change order(Drag and drop)</h3>
                <InfoBox content={this.props.content} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <LanguageTags
                defaultLanguages={this.props.defaultLanguages}
                languages={this.props.languages}
                languageChange={this.props.languageChange}
                onDragEnd={this.props.onDragEnd}
              />
            </Grid.Row>
          </Grid>
        </Sidebar>
      </div>
    );
  }
}

export default HorizontalSidebar;
