import axios from "axios";

function create(pname, token) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };

  return axios
    .post(
      process.env.REACT_APP_BACKEND_URL + "/api/v1/versions",
      { name: pname },
      auth
    )
    .then(result => {});
}

function index() {
  return axios.get(process.env.REACT_APP_BACKEND_URL + "/api/v1/versions");
}

function find(id, token) {
  return axios.get(process.env.REACT_APP_BACKEND_URL + "/api/v1/version/" + id);
}

function update(pname, token, id) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };

  return axios.put(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/version/" + id,
    { name: pname },
    auth
  );
}

function destroy(id, token) {
  const auth = {
    headers: { Authorization: "Token " + token }
  };

  return axios.delete(
    process.env.REACT_APP_BACKEND_URL + "/api/v1/version/" + id,
    auth
  );
}

export const versionService = {
  create,
  index,
  find,
  update,
  destroy
};
