import React, { Component } from "react";
import Links from "../links/links";
import ParsedHTML from "../parsedHTML/parsedHTML";
import { EditorState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import createCodeEditorPlugin from "draft-js-code-editor-plugin";
import { Editor } from "react-draft-wysiwyg";
import "./box_container.css";

class BoxContainer extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    plugins: [createCodeEditorPlugin()]
  };

  componentDidMount() {
    if (this.props.propertyData[0] !== undefined) {
      let content = JSON.parse(this.props.propertyData[0].wysiwyg);
      const some = EditorState.createWithContent(convertFromRaw(content));
      this.setState({
        editorState: some
      });
    }
  }

  render() {
    let data = this.props.propertyData[0];

    let links = propertyLinks => {
      return data !== undefined && data[propertyLinks] !== "" ? (
        data[propertyLinks] !== undefined &&
        data[propertyLinks] !== [] &&
        data[propertyLinks][0] !== undefined ? (
          <Links
            links={this.props.propertyData[0][propertyLinks]}
            linksName={propertyLinks}
          />
        ) : (
          ""
        )
      ) : (
        <span />
      );
    };

    let content = content => {
      return data !== undefined && data[content] !== "" ? (
        <ParsedHTML content={this.props.propertyData[0][content]} />
      ) : (
        ""
      );
    };

    var divStyle = {
      height: "auto !important",
      border: "none !important",
      overflow: "auto !important"
    };

    return (
      <div className="box-container">
        {content("description")}
        <Editor
          editorState={this.state.editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="editorWrapper show"
          editorClassName="wysiwyg"
          readOnly={true}
          toolbarHidden
          plugins={this.state.plugins}
          style={divStyle}
        />
        {links("tutorial_links")}
        {links("docs_links")}
        {links("cheetsheet_links")}
        {links("stackoverflow_links")}
        {links("language_tags")}
        {links}
      </div>
    );
  }
}

export default BoxContainer;
