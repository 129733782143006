import React, { Component } from "react";
import Header from "../../header/header";
import { Button, Form, Input, TextArea, Icon } from "semantic-ui-react";
import { categoryService } from "../../../services/category.service";
import { languageService } from "../../../services/language.service";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Footer from "../../footer/footer";
import { Header as SemanticHeader } from "semantic-ui-react";
import Select from "react-select";

class newCompare extends Component {
  state = {
    name: "",
    description: "",
    cheatsheets: [],
    cheatsheet_ids: [],
    redirect: false
  };

  componentDidMount() {
    languageService
      .index(this.props.username, this.props.token)
      .then(response => {
        let newCheatsheets = response.data;
        this.setState({ cheatsheets: newCheatsheets });
      });
  }

  handleSubmit = e => {
    e.preventDefault();
    const name = this.state.name;
    const description = this.state.description;
    const cheatsheets = this.state.cheatsheet_ids;
    const token = this.props.token;
    categoryService
      .compare_create(name, description, cheatsheets, token)
      .then(response => {
        return this.setState({ redirect: true });
      });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  goBack = e => {
    this.props.history.goBack();
  };

  handleSelect = (field, opts) => {
    let newCheatsheets = [];
    newCheatsheets = opts.map(opt => opt["value"]);
    this.setState({ cheatsheet_ids: newCheatsheets });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/admin/compare/" />;
    }

    return (
      <div>
        <Header />
        <div className="section_content">
          <div className="">
            <a onClick={this.goBack} href="#" class="back-link">
              <Icon name="angle left" /> Go Back
            </a>
          </div>
        </div>
        <Form className="form" onSubmit={this.handleSubmit}>
          <SemanticHeader
            as="h4"
            content="Add a new comparison"
            textAlign="left"
          />
          <Form.Group widths="equal">
            <Form.Field
              id="form-input-control-error-name"
              control={Input}
              label="Name"
              placeholder="Name"
              value={this.state.name}
              name="name"
              onChange={this.handleChange}
              required
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              id="form-input-control-error-name"
              control={Select}
              options={this.state.cheatsheets.map(cheatsheet => {
                return { label: cheatsheet.name, value: cheatsheet._id };
              })}
              isMulti
              label="Cheatsheet to Compare"
              placeholder="Select Cheatsheets"
              value={this.state.cheatsheets.map(cheatsheet =>
                this.state.cheatsheet_ids.includes(cheatsheet._id)
                  ? { label: cheatsheet.name, value: cheatsheet._id }
                  : ""
              )}
              name="cheatsheet_id"
              onChange={opts => this.handleSelect("category_ids", opts)}
              required
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field
              id="form-textarea-control-description"
              control={TextArea}
              label="Description"
              placeholder="Description"
              value={this.state.description}
              name="description"
              onChange={this.handleChange}
              required
            />
          </Form.Group>

          <Form.Field
            id="form-button-control-public"
            control={Button}
            content="Submit"
            color="violet"
            position="right"
            textAlign="right"
            float="right"
            right
            label=""
          />
        </Form>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username
  };
};

export default connect(mapStateToProps)(newCompare);
