import React, { Component } from "react";
import Header from "../../components/header/header";
import TableMatrix from "../.././components/table/tableMatrix";
import Footer from "../.././components/footer/footer";
import { mainService } from "../../services/main.service";
import { connect } from "react-redux";
import "../../style.css";
import { categoryService } from "../../services";
import Nav from "../../components/nav/nav";
import { Redirect } from "react-router-dom";
import { Button, Icon, Image, Menu, Segment, Sidebar } from "semantic-ui-react";
import HorizontalSidebar from "../horizontalSidebar/horizontalSidebar";

class UserCategories extends Component {
  state = {
    languages_data: [],
    data: [],
    properties: [],
    languages: [],
    defaultLanguages: [],
    tags: [],
    compare: [],
    activeItem: "",
    loggedIn: false,
    user: null,
    redirect_to_unauthorized: false
  };

  componentDidMount() {
    this.getTagsFromDB();
  }

  getTagsFromDB = () => {
    categoryService
      .compare(this.props.match.params.username, this.props.token)
      .then(res => {
        this.setState({
          compare: res.data
        });
      });

    categoryService
      .index(this.props.match.params.username, this.props.token)
      .then(res => {
        res.data.not
          ? this.setState({ redirect_to_unauthorized: true })
          : this.setState({
              tags: res.data
            });

        mainService
          .main(
            this.props.match.params.username,
            this.props.match.params.category_id,
            this.props.token
          )
          .then(res => {
            this.setState({
              data: res.data.properties,
              languages_data: res.data.languages,
              properties: res.data.properties,
              languages: res.data.languages.map(language => language.name),
              defaultLanguages: res.data.languages.map(
                language => language.name
              )
            });
          });
      });
  };

  getCategory = () => {
    let category = this.state.tags.filter(tag => {
      return tag._id === this.props.match.params.category_id;
    });
    return category[0] ? category[0].name : "Fetching.. wait...";
  };

  filter_data = event => {
    const value = event.target.value;
    if (value !== undefined || value !== "" || value !== null) {
      var properties = this.state.data.map(property => {
        if (property.name.toLowerCase().includes(event.target.value)) {
          return property;
        }
        return null;
      });
      properties = properties.filter(n => n);
      this.setState({
        properties: properties
      });
    } else {
      this.setState({
        properties: this.state.data
      });
    }
  };

  languageChange = language => {
    let languages = [...this.state.defaultLanguages];
    if (this.state.languages.includes(language)) {
      languages = languages.filter(lang => this.state.languages.includes(lang));
      languages = languages.filter(lang => lang !== language);
      this.setState({ languages: languages });
    } else {
      languages = languages.filter(
        lang => this.state.languages.includes(lang) || lang === language
      );
      this.setState({ languages: languages });
    }
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const defaultLanguages = this.reorder(
      this.state.defaultLanguages,
      result.source.index,
      result.destination.index
    );
    this.setState({ defaultLanguages: defaultLanguages });

    if (this.state.languages.includes(result.draggableId)) {
      const languages = this.reorder(
        this.state.languages,
        result.source.index,
        result.destination.index
      );
      this.setState({ languages: languages });
    }
  };

  languageDragEnd = result => {
    if (!result.destination) {
      return;
    }
    let languages = ["javascript", "ruby", "python"];
    this.setState({ languages: languages });
  };

  render() {
    if (this.state.redirect_to_unauthorized) {
      return <Redirect to="/unauthorized/" />;
    }
    return (
      <div className="App">
        <Header
          _logout={this._logout}
          loggedIn={this.state.loggedIn}
          filter_data={this.filter_data}
          search={true}
        />
        <Nav
          tags={this.state.tags}
          user={this.props.match.params.username}
          id={this.props.match.params.category_id}
          compare={this.state.compare}
        />

        <Sidebar.Pushable>
          <HorizontalSidebar
            content={
              "Category " +
              this.getCategory() +
              "  By " +
              this.props.match.params.username
            }
            defaultLanguages={this.state.defaultLanguages}
            languages={this.state.languages}
            languageChange={this.languageChange}
            onDragEnd={this.onDragEnd}
          />
          <Sidebar.Pusher as={Segment}>
            <TableMatrix
              languages={this.state.languages}
              defaultLanguages={this.state.defaultLanguages}
              properties={this.state.properties}
              data={this.state.languages_data}
              languageChange={this.languageChange}
              onDragEnd={this.onDragEnd}
              user={this.props.match.params.username}
            />
            <Footer />
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username,
    user: ownProps.params
  };
};

export default connect(mapStateToProps)(UserCategories);
