import React, { Component } from "react";
import Header from "../../../components/header/header";

import TableMatrix from "../../table/tableMatrix";
import Footer from "../../footer/footer";

import { mainService } from "../../../services/main.service";
import { connect } from "react-redux";
import "../../../style.css";
import { categoryService } from "../../../services";
import { languageService } from "../../../services/language.service";
import Nav from "../../../components/nav/nav";
import InfoBox from "../../../components/infoBox/infoBox";
import { Redirect } from "react-router-dom";
import { Button, Icon, Modal } from "semantic-ui-react";
import { addLanguageTempPasswordToStore } from "../../../actions/loginActions";
// import { addLanguageTempPasswordToStore } from "../../actions/loginActions";
import { Form } from "semantic-ui-react";

class Language extends Component {
  state = {
    languages_data: [],
    properties: [],
    tags: [],
    compare: [],
    data: [],
    languages: [],
    temporary_password: "",
    activeItem: "",
    loggedIn: false,
    category_id: "",
    user: null,
    param_id: "",
    require_password: false,
    redirect_to_unauthorized: false
  };

  search = (nameKey, myArray) => {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[0].name === nameKey) {
        return [
          { name: "5cd53bb2f06d4900228c3b7e", temporary_password: "123" }
        ];
      }
    }
  };

  componentDidMount() {
    let temp = this.props.languageTempPasswords
      ? JSON.parse(this.props.languageTempPasswords)
      : [];

    let foundValue = temp.find(
      o => o.name === this.props.match.params.language_id
    );

    if (foundValue) {
      this.setState({
        temporary_password: foundValue.temporary_password
      });
      this.getPrivateStatus(foundValue.temporary_password).then(res => {
        if (!this.state.require_password) {
          this.getTagsFromDB();
        }
      });
    } else {
      this.getPrivateStatus("").then(res => {
        if (!this.state.require_password) {
          this.getTagsFromDB();
        }
        //this.checkUserLogin();
      });
    }
  }

  getPrivateStatus = temporary => {
    return languageService
      .checkPrivate(
        this.props.match.params.language_id,
        this.props.match.params.username,
        temporary,
        this.props.token
      )
      .then(res => {
        this.setState({
          require_password: res.data.status
        });
      });
  };

  handleTempPassword = () => {
    languageService
      .checkPrivate(
        this.props.match.params.language_id,
        this.props.match.params.username,
        this.state.temporary_password,
        this.props.token
      )
      .then(res => {
        if (res.data.status === false) {
          this.props.addLanguageTempPasswordToStore(
            this.props.match.params.language_id,
            this.state.temporary_password
          );
          localStorage.setItem(
            "languageTempPasswords",
            JSON.stringify(this.props.languageTempPasswords)
          );
          this.setState({
            require_password: res.data.status
          });
          this.getTagsFromDB();
        }
      });
  };

  getLanguage = () => {
    let language = this.state.languages_data.filter(language => {
      return language._id === this.props.match.params.language_id;
    });
    return language[0] ? language[0].name : "Fetching.. wait...";
  };

  filter_data = event => {
    const value = event.target.value;
    if (value !== undefined || value !== "" || value !== null) {
      var properties = this.state.data.map(property => {
        if (property.name.toLowerCase().includes(event.target.value)) {
          return property;
        }
        return null;
      });
      properties = properties.filter(n => n);
      this.setState({
        properties: properties
      });
    } else {
      this.setState({
        properties: this.state.data
      });
    }
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  getTagsFromDB = () => {
    categoryService
      .compare(this.props.match.params.username, this.props.token)
      .then(res => {
        this.setState({
          compare: res.data
        });
      });

    categoryService
      .index(this.props.match.params.username, this.props.token)
      .then(res => {
        res.data.not
          ? this.setState({ redirect_to_unauthorized: true })
          : this.setState({
              tags: res.data
            });
        mainService
          .language(
            this.props.match.params.username,
            this.props.match.params.language_id,
            this.props.token
          )
          .then(res => {
            this.setState({
              data: res.data.properties,
              languages_data: res.data.languages,
              properties: res.data.properties,
              languages: res.data.languages.map(language => language.name),
              category_id: res.data.languages[0].language_tags[0]
            });
          });
      });
  };

  render() {
    if (this.state.redirect_to_unauthorized) {
      return <Redirect to="/unauthorized/" />;
    }
    if (this.state.require_password) {
      return (
        <h1>
          {Array.apply(null, { length: 20 }).map((e, i) => (
            <h3 className="" key={i}>
              Password Protected
            </h3>
          ))}
          <Modal basic size="small" defaultOpen={true}>
            <Modal.Content>
              <p>This is password protected</p>
            </Modal.Content>
            <Form onSubmit={this.handleTempPassword}>
              <Form.Field className="form-field">
                <Form.Input
                  placeholder="Enter temporary password"
                  value={this.state.temporary_password}
                  name="temporary_password"
                  onChange={this.handleChange}
                  required
                />
              </Form.Field>
              <Modal.Actions>
                <Button type="submit" color="green" inverted>
                  <Icon name="checkmark" /> Enter Password
                </Button>
              </Modal.Actions>
            </Form>
          </Modal>
        </h1>
      );
    }
    return (
      <div className="App">
        <Header
          _logout={this._logout}
          loggedIn={this.state.loggedIn}
          filter_data={this.filter_data}
          search={true}
        />
        <Nav
          tags={this.state.tags}
          user={this.props.match.params.username}
          id={this.state.category_id}
          compare={this.state.compare}
        />
        <InfoBox
          content={
            "Language " +
            this.getLanguage() +
            "  By " +
            this.props.match.params.username
          }
        />

        <TableMatrix
          languages={this.state.languages}
          defaultLanguages={[]}
          properties={this.state.properties}
          data={this.state.languages_data}
          languageChange={() => {}}
          onDragEnd={() => {}}
          user={this.props.match.params.username}
        />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    email: state.authentication.email,
    username: state.authentication.username,
    languageTempPasswords: state.authentication.languageTempPasswords
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addLanguageTempPasswordToStore: (language_id, temporary_password) =>
      dispatch(addLanguageTempPasswordToStore(language_id, temporary_password))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Language);
