import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./section.css";

class Section extends Component {
  state = {};
  componentDidMount() {}

  linkTo(user, type, id, name, category_id, username) {
    let user_name = user === "not" ? username : user;
    switch (type) {
      case "categories":
        return "/users/" + user_name + "/categories/" + id;
      // code block
      case "languages":
        // code block
        return "/users/" + user_name + "/languages/" + id;
      case "users":
        return "/users/" + name + "/categories/" + category_id;
      default:
        return "/";
      // code block
    }
  }

  render() {
    return (
      <Grid className="root-section">
        <div className="root-header">{this.props.heading}</div>
        {this.props.data !== []
          ? this.props.data.map(block => (
              <Grid.Column
                className="root-column"
                largeScreen={2}
                widescreen={1}
                key={block.id}
              >
                <Link
                  to={this.linkTo(
                    this.props.user,
                    this.props.type,
                    block.id,
                    block.name,
                    block.category_id,
                    block.username
                  )}
                  key={block.name}
                >
                  {block.name}
                </Link>
                <span className="root-description">
                  {block.description.substring(0, 80)}...
                </span>
              </Grid.Column>
            ))
          : ""}
      </Grid>
    );
  }
}

export default Section;
